import { gql } from '@apollo/client';
import { TOKEN } from './fragments';
import { axiosBlockchainProxyConfigPrivate, axiosClient } from './api-config';

export const GET_USER = gql`
  query ($id: ID!) @api(name: user) {
    user(id: $id) {
      id
      email
      reserve_email
      first_name
      last_name
      created_at
      avatar
      tel
      timezone
      calculator_id
      marketId: market_id
      companies {
        id
      }
      invited_by_name
      ceo_of
      kyc
      role_id
      role_name
      roles {
        id
        name
      }
      roles_list
      about
      interests {
        id
        body
      }
      agreement {
        validator_agreement
      }
      languages {
        id
        language {
          id
          code
          iso639_1
          english_name
          native_name
        }
        level
      }
      location {
        address
        latLng {
          lat
          lng
        }
        country
        postcode
        locality
      }
      educations {
        id
        establishment
        study
        start_date
        end_date
      }
      projects {
        id
        title
        company_name
        start_date
        end_date
      }
      experiences {
        id
        title
        company_name
        start_date
        end_date
      }
      kyc
    }
  }
`;

export const GET_LIGHTWEIGHT_USER = gql`
  query ($input: UserIdInput!) @api(name: user) {
    getUserByAnyServiceId(input: $input) {
      id
      email
      reserve_email
      first_name
      last_name
      created_at
      avatar
      tel
      timezone
      calculator_id
      market_id
      marketId: market_id
      invited_by_name
      ceo_of
      kyc
      role_name
      roles {
        id
        name
      }
      roles_list
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query @api(name: user) {
    currentUser {
      id
      email
      first_name
      last_name
    }
  }
`;
export const GET_NOTIFICATIONS = gql`
  query ($filter: String!) @api(name: user) {
    notifications(filter: $filter) {
      id
      read_at
      created_at
      user {
        id
        avatar
        first_name
        last_name
        email
      }
      message
      content {
        id
        body
      }
    }
  }
`;
export const GET_AGREEMENT = gql`
  query @api(name: user) {
    agreementPrivacyAndHonor {
      id
      field
      body
    }
  }
`;
export const ACCEPT_INVITE = gql`
  mutation AcceptInvitation($input: AcceptInvitationInput!) @api(name: user) {
    acceptInvitation(input: $input) {
      invitation {
        first_name
        last_name
      }
      url
    }
  }
`;
export const CREATE_NEW_DIGITAL_INDIVIDUAL = gql`
  ${TOKEN}
  mutation CreateNewDigitalIndividual($input: RegisterIndividualInput) @api(name: user) {
    registerIndividual(input: $input) {
      tokens {
        ...TokenFields
        user {
          id
          email
          first_name
          last_name
          market_id
          marketId: market_id
          calculator_id
        }
      }
      status
    }
  }
`;
export const GET_PRO_LOGIN = gql`
  mutation ($input: LoginInput) @api(name: user) {
    login(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        id
        email
        market_id
        marketId: market_id
        calculator_id
        firstName: first_name
        lastName: last_name
      }
    }
  }
`;
export const GET_ROLE = gql`
  query @api(name: user) {
    roles {
      id
      name
    }
  }
`;
export const SEND_INVITE = gql`
  mutation ($input: SendInvitationInput!) @api(name: user) {
    sendInvitation(input: $input) {
      link
      code
      invitation {
        created_at
        expired_at
      }
    }
  }
`;

export const GET_VALIDATOR_AGREEMENT = gql`
  query @api(name: user) {
    agreementValidator {
      id
      field
      body
    }
  }
`;

export const SIGN_VALIDATOR_AGREEMENT = gql`
  query @api(name: user) {
    signAgreementValidator {
      validator_agreement
    }
  }
`;
export const GET_INDUSTRY_GROUPS = gql`
  query @api(name: marketPlace) {
    showIndustryGroups {
      id
      name
      code
      sector {
        id
        name
        code
      }
    }
  }
`;
export const GET_LEGAL_TYPES = gql`
  query @api(name: marketPlace) {
    showCompanyLegalTypes {
      id
      name
    }
  }
`;
export const GET_SUB_INDUSTRIES = gql`
  query ($input: SubIndustryFilter) @api(name: marketPlace) {
    showSubIndustries(input: $input) {
      id
      name
      code
      industries_id
      industry {
        id
        name
        code
        industrial_groups_id
        industrialGroup {
          id
          name
          code
          sector {
            id
            name
            code
          }
        }
      }
    }
  }
`;

export const REMOVE_ITEM_FROM_CART = gql`
  mutation ($input: UserCartDestroyInput!) @api(name: marketPlace) {
    destroyUserCart(input: $input)
  }
`;

export const SHOW_USER_AVAILABLE_CARTS = gql`
  query @api(name: marketPlace) {
    showAvailableUserCarts {
      id
      logos {
        original_name
        path
        type
        mediumPath
        smallPath
      }
      company_name
      email
      products {
        id
        company_id
        name
        status
        carts {
          id
          user_id
          product_id
          quantity
          product_price_id
          availableToOrder
          shopProduct {
            id
            info
            logos {
              path
              mediumPath
              smallPath
              type
            }
            user_id
            name
            company_id
            status
            is_active
            deleted_at
          }
          shopProductPrice {
            id
            product_id
            quantity_all
            min_order_quantity
            price
            productPrice
            productDiscountPrice
            availability
            unit_value
            unit_id
            weight
            weight_unit_id
            unit {
              id
              name
              type
            }
            weightUnit {
              id
              name
              type
            }
          }
        }
      }
    }
  }
`;

export const SHOW_USER_NOT_AVAILABLE_CARTS = gql`
  query @api(name: marketPlace) {
    showNotAvailableUserCarts {
      id
      logos {
        mediumPath
        smallPath
        original_name
        path
        type
      }
      company_name
      email
      products {
        id
        company_id
        name
        status
        carts {
          id
          user_id
          product_id
          quantity
          product_price_id
          availableToOrder
          shopProduct {
            id
            info
            logos {
              mediumPath
              smallPath
              path
              type
            }
            user_id
            name
            company_id
            status
            is_active
            deleted_at
          }
          shopProductPrice {
            id
            product_id
            quantity_all
            min_order_quantity
            price
            productPrice
            productDiscountPrice
            availability
            unit_value
            unit_id
            weight
            weight_unit_id
            unit {
              id
              name
              type
            }
            weightUnit {
              id
              name
              type
            }
          }
        }
      }
    }
  }
`;

export const EDIT_COMPANY_CONTACT_INFOS = gql`
  mutation ($input: CompanyContactInfoInput!) @api(name: marketPlace) {
    editCompanyContactInfos(input: $input) {
      id
      email
      tel
      company_id
      department_name
    }
  }
`;

export const DELETE_COMPANY_CONTACT = gql`
  mutation deleteCompanyContactInfo($id: String!) @api(name: marketPlace) {
    deleteCompanyContactInfo(id: $id)
  }
`;

export const DELETE_COMPANY_SERVICE = gql`
  mutation deleteCompanyService($id: String!) @api(name: marketPlace) {
    deleteCompanyService(id: $id)
  }
`;

export const EDIT_COMPANY_SOCIAL_LINKS = gql`
  mutation ($input: CompanySocialLinkInput!) @api(name: marketPlace) {
    editCompanySocialLinks(input: $input) {
      id
      company_id
      resource
      link
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation ($input: CompanyStoreInput!) @api(name: marketPlace) {
    createCompany(input: $input) {
      id
      user_id
      type
      kyb_completed
      is_draft
      step_number
      tax_vat
      company_name
      address_location_id
      address1
      address2
      email
      website
      established_date
      industry_group_id
      sub_industry_id
      legal_type_id
      for_profit
      is_governmental
      is_open_for_investment
      description
      tags {
        id
        tag
      }
      logos {
        mediumPath
        smallPath
        original_name
        path
        type
      }
      companyGoals {
        id
        name
        description
      }
      addressLocation {
        id
        address
        lat
        lng
        country
        country
        postcode
        locality
      }
      industryGroup {
        id
        name
      }
      subIndustry {
        id
        name
      }
      legalType {
        id
        name
      }
    }
  }
`;

export const CREATE_ADDRESS_LOCATION = gql`
  mutation ($input: LocationStoreInput) @api(name: marketPlace) {
    createAddressLocation(input: $input) {
      id
      address
      lat
      lng
      country
      country_code
      locality
      postcode
    }
  }
`;

export const EDIT_ADDRESS_LOCATION = gql`
  mutation ($input: LocationEditInput) @api(name: marketPlace) {
    editAddressLocation(input: $input) {
      id
      address
    }
  }
`;

export const EDIT_COMPANY_TAGS = gql`
  mutation ($input: TagsCompanyInput!) @api(name: marketPlace) {
    editCompanyTags(input: $input) {
      id
      tag
    }
  }
`;
export const EDIT_COMPANY = gql`
  mutation ($input: CompanyUpdateInput!) @api(name: marketPlace) {
    editCompany(input: $input) {
      id
      user_id
      type
      kyb_completed
      is_draft
      step_number
      tax_vat
      company_name
      address_location_id
      address1
      address2
      email
      website
      established_date
      industry_group_id
      sub_industry_id
      legal_type_id
      for_profit
      is_governmental
      is_open_for_investment
      description
      tags {
        id
        tag
      }
      logos {
        original_name
        path
        mediumPath
        smallPath
        type
      }
      companyGoals {
        id
        name
        description
      }
      addressLocation {
        id
        address
        lat
        lng
        country
        country
        postcode
        locality
      }
      industryGroup {
        id
        name
      }
      subIndustry {
        id
        name
      }
      legalType {
        id
        name
      }
    }
  }
`;

export const CREATE_COMPANY_SERVICE = gql`
  mutation ($input: CompanyServiceStoreInput!) @api(name: marketPlace) {
    createCompanyService(input: $input) {
      id
      name
      description
      company_id
      category_id
      serviceCategory {
        id
        name
      }
    }
  }
`;

export const CREATE_COMPANY_SERVICES = gql`
  mutation ($input: CompanyServiceStoreManyInput!) @api(name: marketPlace) {
    createCompanyServices(input: $input) {
      id
      name
      description
      company_id
      category_id
      serviceCategory {
        id
        name
      }
    }
  }
`;

export const EDIT_OR_DELETE_COMPANY_SERVICES = gql`
  mutation ($input: CompanyServiceEditManyInput!) @api(name: marketPlace) {
    editWithDeleteCompanyServices(input: $input) {
      id
      name
      description
      company_id
      category_id
      serviceCategory {
        id
        name
      }
    }
  }
`;

export const EDIT_COMPANY_SERVICE = gql`
  mutation ($input: CompanyServiceUpdateInput!) @api(name: marketPlace) {
    editCompanyService(input: $input) {
      id
      name
      description
      company_id
      category_id
      serviceCategory {
        id
        name
      }
    }
  }
`;

export const GET_SERVICE_CATEGORIES = gql`
  query @api(name: marketPlace) {
    showAllServiceCategories {
      id
      name
    }
  }
`;

export const GET_DELIVERY_METHODS = gql`
  query @api(name: marketPlace) {
    showAllCompanyDeliveryMethodData {
      company_id
      user_shop_id
      delivery_shipping
      delivery_digital
      delivery_pickup
      delivery_hand
      is_post_worldwide
      pickupAddresses {
        id
        address_location_id
        delivery_pickup_price
        currency_id
        addressLocation {
          address
        }
        currency {
          id
          code
        }
      }
      handAddresses {
        id
        address_location_id
        distance
        distance_unit_id
        delivery_price
        currency_id
        addressLocation {
          address
        }
        currency {
          id
          name
        }
        distanceUnit {
          name
        }
      }
    }
  }
`;

export const GET_URL_FOR_PUBLIC_UPLOAD = gql`
  query getUrlForPublicUpload($input: sFile) @api(name: marketPlace) {
    getUrlForPublicUpload(input: $input) {
      url
      download_url
    }
  }
`;

export const GET_URL_FOR_PRIVATE_UPLOAD = gql`
  query getUrlForPrivateUpload($input: sFile) @api(name: marketPlace) {
    getUrlForPrivateUpload(input: $input) {
      url
      download_url
    }
  }
`;

export const EDIT_WITH_DELETE_COMPANY_LOGOS = gql`
  mutation editWithDeleteCompanyLogos($input: CompanyFileEditDataInput!) @api(name: marketPlace) {
    editWithDeleteCompanyLogos(input: $input) {
      id
      original_name
      file
      extension
      size
      type
      pivot {
        visibility
      }
      path
    }
  }
`;

export const ADD_FILES_TO_MILESTONES = gql`
  mutation ($input: MilestoneFilesInput!) @api(name: marketPlace) {
    addFilesToMilestone(input: $input) {
      id
      history_id
      milestone_id
      period_type
      period
      price
      previous_period_type
      previous_period
      previous_price
      created_at
      updated_at
      status
      deleted_at
      files {
        id
        parent_id
        original_name
        file
        extension
        size
        type
        path
        created_at
        updated_at
        smallPath
        mediumPath
      }
    }
  }
`;

export const ACCEPT_MILESTONE_IN_ORDER = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    acceptMilestoneInOrder(id: $id) {
      id
      history_id
      milestone_id
      status
      period_type
      period
      price
      previous_period_type
      previous_period
      previous_price
      created_at
      updated_at
      deleted_at
      files {
        id
        parent_id
        original_name
        file
        extension
        size
        type
        path
        created_at
        updated_at
        smallPath
        mediumPath
      }
    }
  }
`;

export const EDIT_COMPANY_GOALS = gql`
  mutation editCompanyGoals($input: EditCompanyGoalsInput!) @api(name: marketPlace) {
    editCompanyGoals(input: $input) {
      id
      name
      description
    }
  }
`;

export const EDIT_PAYMENT_ACCOUNT_GET_LINK = gql`
  mutation editPaymentAccountAndGetLink($input: SellerAccountEditInput!) @api(name: marketPlace) {
    editPaymentAccountAndGetLink(input: $input) {
      url
      paymentAccount {
        id
        company_id
        completed_onboarding
      }
    }
  }
`;

export const GET_PAYMENT_ACCOUNT_LOGIN_LINK = gql`
  query getPaymentAccountLoginLink($input: SellerAccountLoginInput!) @api(name: marketPlace) {
    getPaymentAccountLoginLink(input: $input) {
      url
    }
  }
`;

export const SHOW_ALL_COMPANY_GOALS = gql`
  query ($company_id: ID) @api(name: marketPlace) {
    showAllCompanyGoals(company_id: $company_id) {
      id
    }
  }
`;

export const EDIT_COMPANY_DELIVERY_METHODS = gql`
  mutation ($input: DeliveryMethodInput!) @api(name: marketPlace) {
    editCompanyDeliveryMethods(input: $input) {
      id
      user_id
      company_id
      delivery_pickup
      delivery_hand
      delivery_shipping
      delivery_digital
      created_at
      deleted_at
      shopAddresses {
        id
        user_id
        user_shop_id
        info
        name
        contact_person
        tel
        email
        address_location_id
        addressLocation {
          address
        }
        availability
        district
        deadline_days
        is_pickup
        distance
        distance_unit_id
        delivery_pickup_price
        currency_id
        currency {
          id
          name
          symbol
          code
        }
        schedules {
          id
          from
          to
          weekDays {
            id
            name
          }
        }
        created_at
      }
    }
  }
`;

export const GET_AVAILABLE_CURRENCIES = gql`
  query ($payment_system: PaymentSystem!) @api(name: marketPlace) {
    getAvailableCurrencies(payment_system: $payment_system) {
      id
      name
      code
      symbol
      decimals
    }
  }
`;

export const GET_UNITS = gql`
  query ($input: UnitFilterInput) @api(name: marketPlace) {
    getUnits(input: $input) {
      id
      name
      type
      system_type
      is_unit
      is_order_quantity
    }
  }
`;

export const GET_USER_COMPANIES_LIST = gql`
  query ($user_id: String!) @api(name: marketPlace) {
    showAllCompaniesByUserId(user_id: $user_id) {
      id
      user_id
      company_name
      kyb_completed
      logos {
        mediumPath
        smallPath
        original_name
        path
        type
      }
    }
  }
`;

export const IS_USER_HAS_COMPANIES = gql`
  query ($input: UserHasCompanyInput!) @api(name: marketPlace) {
    isUserHasCompany(input: $input)
  }
`;

export const GET_CUSTOMER_ORDERS_BY_STATUS = gql`
  query ($input: CustomerOrderFilterInput, $first: Int!, $page: Int) @api(name: marketPlace) {
    getCustomerOrdersByStatus(input: $input, first: $first, page: $page) {
      data {
        id
        order_id
        created_at
        order {
          id
          amount
          status
          payment_process_start
          payment_system
          payment_status
          contactInfo {
            id
            user_id
            first_name
            last_name
            tel
            email
            comment
          }
          type
        }
        company {
          company_name
          logos {
            path
            mediumPath
            smallPath
            type
          }
        }
        company_id
        status
        payout_amount
        currency_id
        payout_status
        orderItems {
          id
          shop_service_id
          shopService {
            id
            logos {
              path
              mediumPath
              smallPath
              type
            }
            orderCustomization {
              id
              status
              comment
              service {
                id
                name
                description
                company {
                  company_name
                }
                performance_term_period
                performance_term_period_type
                price_type
                basic_price
                discount_price
                discount_period_start
                discount_period_end
                hourly_rate
                maximum_number_hours
                hourly_discount_price
                hourly_discount_period_start
                hourly_discount_period_end
              }
              histories {
                id
                modifier_id
                customization_id
                period_type
                period
                price
                previous_period_type
                previous_period
                previous_price
                created_at
                updated_at
                deleted_at
                milestonesHistory {
                  id
                  history_id
                  milestone_id

                  milestone {
                    id
                    period
                    period_type
                    price
                    max_hours
                    description
                    provide_documentation
                  }
                  period_type
                  period
                  price
                  previous_period_type
                  previous_period
                  previous_price
                  created_at
                  updated_at
                  deleted_at
                }
              }
              user {
                id
                email
                first_name
                last_name
                avatar
                tel
                roles {
                  id
                  name
                }
              }
            }
          }
          delivery {
            id
            delivery_method
            shop_address_id
            order_item_id
            shopAddress {
              id
              address_location_id
              delivery_pickup_price
              currency_id
              is_pickup
              name
              schedules {
                id
                from
                to
                weekDays {
                  id
                  name
                }
              }
              addressLocation {
                id
                address
                lat
                lng
                country
                postcode
                locality
              }
              currency {
                id
                name
                code
                symbol
                decimals
              }
            }
            delivery_date
            created_at
            updated_at
            deleted_at
          }

          order_group_id
          shop_product_id
          quantity
          price
          seller_price
          shop_product_price_id
          currency_id
          currency {
            name
          }
          availableToPay
          shopProduct {
            name
            info
            logos {
              path
              mediumPath
              smallPath
              type
            }
          }
          shopProductPrice {
            id
            product_id
            price
            currency_id
            unit_value
            unit_id
            unit {
              name
              type
            }
            min_order_quantity
            quantity_in_pack
            discount_price
            quantity_all
            productPrice
            productDiscountPrice
          }
        }
        orderGroupPriceWithFee {
          groupPrice
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const CONFIRM_ORDER = gql`
  mutation ($input: OrderConfirmInput!) @api(name: marketPlace) {
    confirmOrder(input: $input) {
      id
      order_id
      created_at
      order {
        id
        amount
        status
        payment_process_start
        payment_system
        payment_status
        type
        user {
          id
          email
          first_name
          last_name
          avatar
          tel
          roles {
            id
            name
          }
        }
        contactInfo {
          id
          user_id
          first_name
          last_name
          tel
          email
          comment
        }
      }
      company {
        company_name
        logos {
          path
          mediumPath
          smallPath
          type
        }
      }
      company_id
      status
      payout_amount
      currency_id
      payout_status
      orderItems {
        id
        delivery {
          id
          delivery_method
          shop_address_id
          order_item_id
          shopAddress {
            id
            address_location_id
            delivery_pickup_price
            currency_id
            is_pickup
            name
            schedules {
              id
              from
              to
              weekDays {
                id
                name
              }
            }
            addressLocation {
              id
              address
              lat
              lng
              country
              postcode
              locality
            }
            currency {
              id
              name
              code
              symbol
              decimals
            }
          }
          delivery_date
          created_at
          updated_at
          deleted_at
        }

        order_group_id
        shop_product_id
        quantity
        price
        seller_price
        shop_product_price_id
        currency_id
        currency {
          name
        }
        availableToPay
        shopProduct {
          name
          info
          logos {
            path
            mediumPath
            smallPath
            type
          }
        }
        shopProductPrice {
          id
          product_id
          price
          currency_id
          unit_value
          unit_id
          unit {
            name
            type
          }
          min_order_quantity
          quantity_in_pack
          discount_price
          quantity_all
          productPrice
          productDiscountPrice
        }
      }
      orderGroupPriceWithFee {
        groupPrice
      }
    }
  }
`;

export const GET_COMPANIES_ORDERS_BY_STATUS = gql`
  query ($input: CompanyOrderSelectByStatusInput, $first: Int!, $page: Int)
  @api(name: marketPlace) {
    getAllCompanyOrdersByStatus(input: $input, first: $first, page: $page) {
      data {
        id
        order_id
        created_at
        order {
          status
          payment_process_start
          id
          amount
          payment_system
          payment_status
          contactInfo {
            id
            user_id
            first_name
            last_name
            tel
            email
            comment
          }
          type
        }
        company {
          company_name
          logos {
            path
            mediumPath
            smallPath
            type
          }
        }
        company_id
        status
        payout_amount
        currency_id
        payout_status
        orderItems {
          id
          delivery {
            id
            delivery_method
            shop_address_id
            order_item_id
            shopAddress {
              id
              address_location_id
              delivery_pickup_price
              currency_id
              is_pickup
              name
              schedules {
                id
                from
                to
                weekDays {
                  id
                  name
                }
              }
              addressLocation {
                id
                address
                lat
                lng
                country
                postcode
                locality
              }
              currency {
                id
                name
                code
                symbol
                decimals
              }
            }
            delivery_date
            created_at
            updated_at
            deleted_at
          }

          order_group_id
          shop_product_id
          shop_service_id
          shopService {
            id
            logos {
              path
              mediumPath
              smallPath
              type
            }
            orderCustomization {
              id
              comment
              service {
                id
                name
                description
                company {
                  company_name
                }
                performance_term_period
                performance_term_period_type
                price_type
                basic_price
                discount_price
                discount_period_start
                discount_period_end
                hourly_rate
                maximum_number_hours
                hourly_discount_price
                hourly_discount_period_start
                hourly_discount_period_end
              }
              histories {
                id
                modifier_id
                customization_id
                period_type
                period
                price
                previous_period_type
                previous_period
                previous_price
                created_at
                updated_at
                deleted_at
                milestonesHistory {
                  id
                  history_id
                  milestone_id
                  milestone {
                    id
                    period
                    period_type
                    price
                    max_hours
                    description
                    provide_documentation
                  }
                  period_type
                  period
                  price
                  previous_period_type
                  previous_period
                  previous_price
                  created_at
                  updated_at
                  deleted_at
                }
              }
              user {
                id
                email
                first_name
                last_name
                avatar
                tel
                roles {
                  id
                  name
                }
              }
            }
          }
          quantity
          price
          seller_price
          shop_product_price_id
          currency_id
          currency {
            name
          }
          availableToPay
          shopProduct {
            name
            info
            logos {
              path
              mediumPath
              smallPath
              type
            }
          }
          shopProductPrice {
            id
            product_id
            price
            currency_id
            unit_value
            unit_id
            unit {
              name
              type
            }
            min_order_quantity
            quantity_in_pack
            discount_price
            quantity_all
            productPrice
            productDiscountPrice
          }
        }
        orderGroupPriceWithFee {
          groupPrice
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const GET_ACTIVE_USER_COMPANIES_LIST = gql`
  query @api(name: marketPlace) {
    showActiveUserCompanies {
      id
      user_id
      company_name
      kyb_completed
      logos {
        original_name
        mediumPath
        smallPath
        path
        type
      }
    }
  }
`;

export const GET_COMPANIES_WITH_PAGINATION = gql`
  query ($input: CompaniesSearchInput, $first: Int!, $page: Int) @api(name: marketPlace) {
    getCompaniesByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        company_name
        kyb_completed
        tags {
          id
          tag
        }
        logos {
          original_name
          mediumPath
          smallPath
          path
          type
        }
      }
    }
  }
`;

export const GET_COMPANY_BY_ID = gql`
  query ($id: String!) @api(name: marketPlace) {
    showCompanyById(id: $id) {
      id
      user_id
      type
      kyb_completed
      has_access_for_investment
      is_draft
      step_number
      tax_vat
      company_name
      address_location_id
      address1
      address2
      email
      website
      established_date
      industry_group_id
      sub_industry_id
      legal_type_id
      for_profit
      is_governmental
      is_open_for_investment
      description
      tags {
        id
        tag
      }
      logos {
        mediumPath
        smallPath
        original_name
        path
        type
      }
      companyGoals {
        id
        name
        description
      }
      addressLocation {
        id
        address
        lat
        lng
        country
        country
        postcode
        locality
      }
      industryGroup {
        id
        name
      }
      subIndustry {
        id
        name
      }
      legalType {
        id
        name
      }
    }
  }
`;

export const GET_SHORT_COMPANY_BY_ID = gql`
  query ($id: String!) @api(name: marketPlace) {
    showCompanyById(id: $id) {
      id
      company_name
      has_access_for_investment
      kyb_completed
      logos {
        mediumPath
        smallPath
        original_name
        path
        type
      }
    }
  }
`;

export const GET_COMPANY_KYB_LIST_DATA = gql`
  query ($input: KybFilterInput!, $first: Int!, $page: Int) @api(name: marketPlace) {
    getKybChecks(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        check_process_status
        check_type
        add_user_id
        company_id
        full_name
        ext_check_id
        document_type
        document_version
        report_name
        kyb_status
        created_at
        updated_at
        mainReport {
          id
          path
        }
        detailedReport {
          id
          path
        }
        payment {
          id
          status
        }
      }
    }
  }
`;

export const COMPANY_KYB_CHECK_DELETE = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    deleteKYBCheck(id: $id)
  }
`;

export const GET_COMPANY_KYB_CHECK_PAYMENT_INFO = gql`
  query ($input: PaymentSystemPricesFilterInput!) @api(name: marketPlace) {
    getPaymentSystemPrices(input: $input) {
      id
      ext_price_id
      lookup_key
      price
      archived_at
      currency {
        id
        code
        symbol
      }
    }
  }
`;

export const COMPANY_KYB_START_WITH_PAYMENT = gql`
  mutation ($input: KYBCheckPaymentInput!) @api(name: marketPlace) {
    payAndStartKYBCheck(input: $input) {
      url
      internalService {
        id
        exact_id
        payment_customer_id
        external_id
        payment_price_id
        amount
        currency_id
        status
        payment_status
        payment_system
        created_at
        canceled_at
        price {
          id
          lookup_key
        }
        currency {
          id
          symbol
          code
        }
      }
    }
  }
`;

export const GET_COMPANY_EXTENDED_BY_ID = gql`
  query ($id: String!) @api(name: marketPlace) {
    showCompanyById(id: $id) {
      id
      user_id
      type
      kyb_completed
      has_access_for_investment
      is_draft
      step_number
      tax_vat
      company_name
      address_location_id
      address1
      address2
      email
      website
      established_date
      industry_group_id
      sub_industry_id
      legal_type_id
      for_profit
      is_governmental
      is_open_for_investment
      description
      tags {
        id
        tag
      }
      logos {
        id
        extension
        size
        original_name
        path
        mediumPath
        smallPath
        type
      }
      companyGoals {
        id
        name
        description
      }
      addressLocation {
        id
        address
        lat
        lng
        country
        country
        postcode
        locality
      }
      industryGroup {
        id
        name
      }
      subIndustry {
        id
        name
      }
      legalType {
        id
        name
      }
      companySocialLinks {
        id
        resource
        link
      }
      companyContactInfos {
        id
        email
        tel
        department_name
      }
    }
  }
`;

export const GET_PAYMENT_ACCOUNTS_BY_COMPANY = gql`
  query ($id: String!) @api(name: marketPlace) {
    showCompanyById(id: $id) {
      id
      has_access_for_investment
      kyb_completed
      paymentAccountList {
        id
        user_id
        account_id
        completed_onboarding
        payment_system
        company_id
      }
    }
  }
`;

export const GET_COMPANY_SERVICES = gql`
  query ($company_id: String!) @api(name: marketPlace) {
    getCompanyService(company_id: $company_id) {
      id
      name
      description
      company_id
      category_id
      serviceCategory {
        id
        name
      }
    }
  }
`;

export const GET_COMPANY_CONTACT_INFOS = gql`
  query ($company_id: String!) @api(name: marketPlace) {
    showAllCompanyContactInfos(company_id: $company_id) {
      id
      email
      tel
      company_id
      department_name
    }
  }
`;
export const GET_COMPANY_DELIVERY_METHOD_DATA = gql`
  query ($company_id: String!) @api(name: marketPlace) {
    showAllCompanyDeliveryMethodData(company_id: $company_id) {
      id
      user_id
      company_id
      delivery_pickup
      delivery_hand
      delivery_shipping
      delivery_digital
      created_at
      deleted_at
      shopAddresses {
        id
        user_id
        info
        user_shop_id
        name
        contact_person
        tel
        email
        address_location_id
        addressLocation {
          address
        }
        availability
        district
        deadline_days
        is_pickup
        distance
        distance_unit_id
        delivery_pickup_price
        currency_id
        currency {
          id
          name
          symbol
          code
        }
        schedules {
          id
          from
          to
          weekDays {
            id
            name
          }
        }
        created_at
      }
    }
  }
`;
export const GET_COMPANY_SOCIAL_LINKS = gql`
  query ($company_id: String!) @api(name: marketPlace) {
    showAllCompanySocialLinks(company_id: $company_id) {
      id
      company_id
      resource
      link
    }
  }
`;

export const GET_PRODUCT_DEFAULT_CATEGORIES = gql`
  query @api(name: marketPlace) {
    getShopProductDefaultCategories {
      id
      name
      add_user_id
      company_id
      description
      created_at
      updated_at
    }
  }
`;
export const GET_PRODUCT_GROUPS = gql`
  query ($input: ShopProductGroupFilterInput, $first: Int!, $page: Int) @api(name: marketPlace) {
    getShopProductGroupsWithDefaults(input: $input, first: $first, page: $page) {
      data {
        id
        name
        category_id
        category {
          id
          name
          add_user_id
          company_id
          description
          created_at
          updated_at
        }
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;
export const GET_IDV_INIT = gql`
  mutation GetIdvInit($input: idvInitInput) @api(name: user) {
    idvInit(input: $input) {
      url
    }
  }
`;

export const CREATE_SHOP_PRODUCT_WITH_OPTIONS = gql`
  mutation ($input: ShopProductWithOptionsStoreInput!) @api(name: marketPlace) {
    createShopProductWithOptions(input: $input) {
      id
      user_id
      name
      company_id
      status
      info
      is_show_nutrition
      is_preorder
      is_retail
      is_wholesale
      is_active
      is_shipping
      is_seller_delivery
      is_pickup
      category_id
      group_id
      is_negotiable_price
      negotiable_price
      category {
        id
        name
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
      group {
        id
        name
        category_id
        category {
          id
          name
          add_user_id
          company_id
          description
          created_at
          updated_at
        }
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
      productPrices {
        id
        product_id
        price
        currency_id
        unit_value
        quantity_in_pack
        unit_id
        delivery_shipping
        delivery_digital
        delivery_pickup
        delivery_hand
        min_order_quantity
        discount_price
        quantity_all
        is_negotiable_price
        negotiable_price
        shipping_price
        shipping_price_scope
        packing_id
        packaging_footprint_price
        products_footprint_price
        weight
        weight_unit_id
        discount_from_datetime
        discount_until_datetime
        valid_from_date
        valid_until_date
        visibility
        availability
        min_order_unit_id
        packing {
          id
          height
          width
          depth
          weight
          length_unit_id
          weight_unit_id
          max_quantity
          created_at
          updated_at
        }
      }
      tags {
        id
        tag
      }
      logos {
        id
        original_name
        file
        extension
        size
        type
        path
        mediumPath
        smallPath
        created_at
        updated_at
      }
      suppliers {
        id
        name
        company_id
        description
        country_id
        tax_vat
        email
        tel
        availability
        country {
          id
          name
          full_name
          code
        }
        logos {
          id
          original_name
          file
          extension
          size
          type
          path
          mediumPath
          smallPath
          created_at
          updated_at
        }
        created_at
        updated_at
      }
    }
  }
`;
export const CREATE_SHOP_PRODUCT_GROUP = gql`
  mutation ($input: ShopProductGroupStoreInput!) @api(name: marketPlace) {
    createShopProductGroup(input: $input) {
      id
      name
      category_id
      category {
        id
        name
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
      add_user_id
      company_id
      description
      created_at
      updated_at
    }
  }
`;
export const CREATE_PRODUCT_REVIEW = gql`
  mutation ($input: ProductReviewStoreInput!) @api(name: marketPlace) {
    createProductReview(input: $input) {
      id
      user_id
      product_id
      stars
      comment
      logos {
        id
        original_name
        file
        extension
        size
        type
        mediumPath
        smallPath
        path
      }
    }
  }
`;

export const PRODUCTS_GROUPS_WITH_PAGINATION = gql`
  query ($input: ShopProductGroupPaginationFilterInput, $first: Int!, $page: Int)
  @api(name: marketPlace) {
    getShopProductGroupsByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
        category_id
        category {
          id
          name
          add_user_id
          company_id
          description
          created_at
          updated_at
        }
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
    }
  }
`;

export const DELETE_PRODUCT_GROUP = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    destroyShopProductGroup(id: $id)
  }
`;

export const DELETE_SHOP_PRODUCT = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    destroyShopProduct(id: $id)
  }
`;

export const GET_SHOP_PRODUCTS_ALL = gql`
  query ($input: ShopProductAllByWithPaginationInput!, $first: Int!, $page: Int)
  @api(name: marketPlace) {
    getAllShopProductsByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        name
        company_id
        info
        is_show_nutrition
        is_preorder
        is_retail
        is_wholesale
        is_active
        is_shipping
        is_seller_delivery
        is_pickup
        category_id
        group_id
        is_negotiable_price
        negotiable_price
        productPrices {
          userCart {
            quantity
          }
        }
        category {
          id
          name
          add_user_id
          company_id
          description
          created_at
          updated_at
        }
        group {
          id
          name
          category_id
          category {
            id
            name
            add_user_id
            company_id
            description
            created_at
            updated_at
          }
          add_user_id
          company_id
          description
          created_at
          updated_at
        }
        lastVisiblePrice {
          id
          price
          currency_id
          currency_symbol
          discount_price
          quantity_all
          discount_from_datetime
          discount_until_datetime
          availability
          availableToBuy
          min_order_quantity
          unit_value
          quantityInCart
        }
        logos {
          id
          original_name
          file
          extension
          size
          mediumPath
          smallPath
          type
          path
        }
        company {
          id
          company_name
          logos {
            id
            type
            original_name
            file
            mediumPath
            smallPath
            path
          }
          userShop {
            id
            user_id
            company_id
            currency_id
            direct_payment
            direct_payment_info
            credit_card
            cryptocurrencies
            cryptocurrencies_info
            delivery_pickup
            delivery_pickup_price
            delivery_pickup_info
            delivery_pickup_base
            delivery_hand
            delivery_hand_price
            delivery_hand_info
            delivery_hand_base
            delivery_shipping
            delivery_shipping_price
            delivery_shipping_info
            delivery_shipping_base
            is_post_worldwide
            delivery_digital
            is_enable_support
            is_blockchain
            is_certified_organic
          }
        }
      }
    }
  }
`;

export const GET_SHOP_SERVICES = gql`
  query ($input: ShopServicesWithPaginationInput!, $first: Int!, $page: Int)
  @api(name: marketPlace) {
    getShopServices(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        company_id
        logos {
          id
          original_name
          file
          extension
          size
          type
          pivot {
            visibility
          }
          mediumPath
          smallPath
          path
          created_at
          updated_at
        }
        company {
          id
          user_id
          company_name
          logos {
            id
            type
            path
            mediumPath
            smallPath
            original_name
          }
        }
        name
        description
        timezone_id
        specific_terms
        shop_service_category_id
        languages {
          id
          english_name
        }
        tags {
          id
          tag
        }
        serviceCategory {
          id
          name
        }
        performance_term_period
        performance_term_period_type
        price_type
        basic_price
        discount_price
        discount_period_start
        discount_period_end
        hourly_rate
        maximum_number_hours
        hourly_discount_price
        hourly_discount_period_start
        hourly_discount_period_end
        detailed_description
        including_vat
        is_fcem_deposit_available
        milestones {
          period
          period_type
          price
          max_hours
          description
          provide_documentation
        }
        currency_id
      }
    }
  }
`;

export const GET_SHOP_PRODUCTS = gql`
  query ($input: ShopProductByWithPaginationInput!, $first: Int!, $page: Int)
  @api(name: marketPlace) {
    getShopProductsByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        name
        company_id
        info
        is_show_nutrition
        is_preorder
        is_retail
        is_wholesale
        is_active
        is_shipping
        is_seller_delivery
        is_pickup
        category_id
        group_id
        is_negotiable_price
        negotiable_price
        category {
          id
          name
          add_user_id
          company_id
          description
          created_at
          updated_at
        }
        group {
          id
          name
          category_id
          category {
            id
            name
            add_user_id
            company_id
            description
            created_at
            updated_at
          }
          add_user_id
          company_id
          description
          created_at
          updated_at
        }
        productPrices {
          userCart {
            quantity
          }
          unit {
            id
            name
            type
            system_type
            is_unit
            is_order_quantity
            is_location
            is_packing
          }
        }
        lastVisiblePrice {
          id
          price
          currency_id
          currency_symbol
          discount_price
          quantity_all
          discount_from_datetime
          discount_until_datetime
          availability
          availableToBuy
          min_order_quantity
          unit_value
          quantityInCart
        }
        logos {
          id
          original_name
          file
          extension
          size
          type
          mediumPath
          smallPath
          path
        }
        company {
          id
          company_name
          logos {
            id
            original_name
            file
            mediumPath
            smallPath
            path
            type
          }
          userShop {
            id
            user_id
            company_id
            currency_id
            direct_payment
            direct_payment_info
            credit_card
            cryptocurrencies
            cryptocurrencies_info
            delivery_pickup
            delivery_pickup_price
            delivery_pickup_info
            delivery_pickup_base
            delivery_hand
            delivery_hand_price
            delivery_hand_info
            delivery_hand_base
            delivery_shipping
            delivery_shipping_price
            delivery_shipping_info
            delivery_shipping_base
            is_post_worldwide
            delivery_digital
            is_enable_support
            is_blockchain
            is_certified_organic
          }
        }
      }
    }
  }
`;

export const GET_CARBON_PRODUCTS = gql`
  query ($input: CarbonSearchInput, $first: Int!, $page: Int) @api(name: marketPlace) {
    getCarbonCreditsByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        name
        company_id
        region_id
        is_draft
        is_fcem_deposit_available
        amount
        quantity
        price
        discount_price
        discount_from_datetime
        discount_until_datetime
        ext_project_id
        ext_credit_id
        link
        vintage_from
        vintage_to
        standard_id
        project_type_id
        address_location_id
        description
        unit_id
        currency_id
        standard {
          id
          name
        }
        projectType {
          id
          name
          logo
          logoPath
        }
        addressLocation {
          id
          address
          lat
          lng
          country
          postcode
          locality
        }
        unit {
          id
          name
          type
          system_type
          is_unit
          is_order_quantity
          is_location
          is_packing
        }
        currency {
          id
          name
          code
          symbol
          decimals
        }
        creditGoals {
          id
          name
          description
        }
        company {
          id
          company_name
          logos {
            id
            original_name
            file
            path
            mediumPath
            smallPath
            type
          }
        }
        logos {
          id
          original_name
          file
          extension
          size
          type
          path
          mediumPath
          smallPath
          created_at
          updated_at
        }
        region {
          id
          name
          code
        }
      }
    }
  }
`;

export const GET_CARBON_PRODUCTS_ALL = gql`
  query ($input: CarbonAllSearchInput, $first: Int!, $page: Int) @api(name: marketPlace) {
    getAllCarbonCreditsByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        name
        company_id
        region_id
        is_draft
        is_fcem_deposit_available
        amount
        quantity
        price
        discount_price
        discount_from_datetime
        discount_until_datetime
        ext_project_id
        ext_credit_id
        link
        vintage_from
        vintage_to
        standard_id
        project_type_id
        address_location_id
        description
        unit_id
        currency_id
        standard {
          id
          name
        }
        projectType {
          id
          name
          logo
          logoPath
        }
        addressLocation {
          id
          address
          lat
          lng
          country
          postcode
          locality
        }
        unit {
          id
          name
          type
          system_type
          is_unit
          is_order_quantity
          is_location
          is_packing
        }
        currency {
          id
          name
          code
          symbol
          decimals
        }
        creditGoals {
          id
          name
          description
        }
        company {
          id
          company_name
          logos {
            id
            mediumPath
            smallPath
            original_name
            file
            type
            path
          }
        }
        logos {
          id
          original_name
          file
          extension
          size
          type
          path
          mediumPath
          smallPath
          created_at
          updated_at
        }
        region {
          id
          name
          code
        }
      }
    }
  }
`;

export const EDIT_PRODUCT_GROUP = gql`
  mutation ($input: ShopProductGroupUpdateInput!) @api(name: marketPlace) {
    editShopProductGroup(input: $input) {
      id
      name
      category_id
      category {
        id
        name
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
      add_user_id
      company_id
      description
      created_at
      updated_at
    }
  }
`;

export const GET_COUNTRIES = gql`
  query @api(name: marketPlace) {
    showCountries {
      id
      name
      full_name
      code
      iso_3166_2
      iso_3166_3
    }
  }
`;

export const GET_MANY_PRODUCT_FILTERS = gql`
  query @api(name: marketPlace) {
    getManyProductFilters {
      categories {
        id
        name
      }
      groups {
        id
        name
      }
      countries {
        id
        name
      }
    }
  }
`;

export const GET_SERVICE_FILTERS = gql`
  query @api(name: marketPlace) {
    getServiceFilters {
      categories {
        id
        name
      }
      countries {
        id
        name
      }
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation ($input: SupplierStoreInput!) @api(name: marketPlace) {
    createSupplier(input: $input) {
      id
      name
      company_id
      description
      country_id
      tax_vat
      email
      tel
      availability
      country {
        id
        name
        code
      }
      logos {
        id
        original_name
        file
        extension
        size
        type
        path
        mediumPath
        smallPath
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;

export const SUPPLIERS_WITH_PAGINATION = gql`
  query ($input: SuppliersSearchInput!, $first: Int!, $page: Int) @api(name: marketPlace) {
    getSuppliersByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
        company_id
        description
        country_id
        tax_vat
        email
        tel
        sort
        availability
        country {
          id
          name
          code
        }
        logos {
          id
          original_name
          file
          extension
          size
          type
          path
          mediumPath
          smallPath
          created_at
          updated_at
        }
        created_at
        updated_at
      }
    }
  }
`;

export const GET_USER_COMPANIES_SHORT_LIST = gql`
  query ($user_id: String!) @api(name: marketPlace) {
    showAllCompaniesByUserId(user_id: $user_id) {
      id
      company_name
      logos {
        mediumPath
        smallPath
        original_name
        path
        type
      }
    }
  }
`;

export const EDIT_SUPPLIER = gql`
  mutation ($input: SupplierUpdateInput!) @api(name: marketPlace) {
    editSupplier(input: $input) {
      id
      name
      company_id
      description
      country_id
      tax_vat
      email
      tel
      availability
      country {
        id
        name
        code
      }
      logos {
        id
        original_name
        file
        extension
        size
        type
        path
        mediumPath
        smallPath
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;

export const EDIT_SUPPLIERS_SORT = gql`
  mutation ($input: [SupplierSortUpdateInput!]!) @api(name: marketPlace) {
    editSuppliersList(input: $input) {
      status
      message
    }
  }
`;

export const DELETE_SUPPLIER = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    destroySupplier(id: $id)
  }
`;

export const CALCULATE_PRICES = gql`
  mutation ($input: CalculatePriceInput!) @api(name: marketPlace) {
    calculatePrices(input: $input) {
      base_prices {
        seller_price
        product_footprint
        packaging_footprint
        tt_fee
        payment_system
        payment_systems_fee
        end_price
      }
      discount_prices {
        seller_price
        product_footprint
        packaging_footprint
        tt_fee
        payment_system
        payment_systems_fee
        end_price
      }
    }
  }
`;

export const SUPPLIER_BY_ID = gql`
  query ($id: String!) @api(name: marketPlace) {
    getSupplierBy(id: $id) {
      id
      name
      company_id
      description
      country_id
      tax_vat
      email
      tel
      availability
      country {
        id
        name
        code
      }
      logos {
        id
        original_name
        file
        extension
        size
        type
        path
        mediumPath
        smallPath
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation @api(name: user) {
    logout {
      status
      message
    }
  }
`;

export const CREATE_AND_UPDATE_USER_CART = gql`
  mutation ($input: UserCartStoreInput!) @api(name: marketPlace) {
    addToUserCart(input: $input) {
      id
      quantity
    }
  }
`;

export const REORDER = gql`
  mutation ($input: [ReorderProductsInput!]!, $id: String!) @api(name: marketPlace) {
    reorderProducts(input: $input, id: $id) {
      id
      user_id
      product_id
      quantity
      product_price_id
      availableToOrder
      shopProduct {
        id
        info
        logos {
          path
          mediumPath
          smallPath
          type
        }
        user_id
        name
        company_id
        status
        is_active
        deleted_at
      }
      shopProductPrice {
        id
        product_id
        quantity_all
        min_order_quantity
        price
        productPrice
        productDiscountPrice
        availability
        unit_value
        unit_id
        weight
        weight_unit_id
        unit {
          id
          name
          type
        }
        weightUnit {
          id
          name
          type
        }
      }
    }
  }
`;

export const SHOW_SHOP_PRODUCT = gql`
  query ($id: String!) @api(name: marketPlace) {
    showShopProduct(id: $id) {
      id
      user_id
      name
      deleted_at
      company_id
      ingredients
      info
      is_preorder
      is_retail
      is_wholesale
      is_active
      category_id
      group_id
      is_patronage
      is_negotiable_price
      negotiable_price
      status
      category {
        id
        name
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
      group {
        id
        name
        category_id
        category {
          id
          name
          add_user_id
          company_id
          description
          created_at
          updated_at
        }
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
      productPrices {
        id
        availableToBuy
        userCart {
          quantity
        }
        quantity_in_pack
        unit {
          id
          name
          type
          system_type
          is_unit
          is_order_quantity
          is_location
          is_packing
        }
        currency {
          id
          name
          code
          symbol
          decimals
        }
        product_id
        price
        basic_price
        basic_discount_price
        has_discount_price
        final_price {
          seller_price
          product_footprint
          packaging_footprint
          tt_fee
          payment_systems_fee
          end_price
        }
        final_discount_price {
          seller_price
          product_footprint
          packaging_footprint
          tt_fee
          payment_systems_fee
          end_price
        }
        currency_id
        unit_value
        unit_id
        delivery_shipping
        delivery_digital
        delivery_pickup
        delivery_hand
        min_order_quantity
        discount_price
        quantity_all
        is_negotiable_price
        negotiable_price
        shipping_price
        shipping_price_scope
        packing_id
        packaging_footprint_price
        products_footprint_price
        weight
        weight_unit_id
        discount_from_datetime
        discount_until_datetime
        valid_from_date
        valid_until_date
        visibility
        availability
        min_order_unit_id
        shopAddress {
          id
          address_location_id
          delivery_pickup_price
          currency_id
          is_pickup
          pivot {
            is_auto_order
          }
          schedules {
            id
            from
            to
            weekDays {
              id
              name
            }
          }
          addressLocation {
            id
            address
            lat
            lng
            country
            postcode
            locality
          }
          currency {
            id
            name
            code
            symbol
            decimals
          }
        }
        packing {
          id
          height
          width
          depth
          weight
          length_unit_id
          weight_unit_id
          max_quantity
          created_at
          updated_at
        }
      }
      tags {
        id
        tag
      }
      logos {
        id
        original_name
        type
        file
        extension
        size
        path
        mediumPath
        smallPath
      }
      company {
        id
        user_id
        type
        kyb_completed
        ext_kyb_id
        kyb_status
        is_draft
        step_number
        tax_vat
        company_name
        address_location_id
        address1
        address2
        email
        website
        established_date
        industry_group_id
        sub_industry_id
        legal_type_id
        for_profit
        is_governmental
        is_open_for_investment
        description
        created_at
        updated_at
        deleted_at
        addressLocation {
          id
          address
          country_id
          country
        }
        industryGroup {
          id
          name
          code
        }
        subIndustry {
          id
          name
          code
          industries_id
        }
        legalType {
          id
          name
        }
        userShop {
          id
          user_id
          company_id
        }
        paymentAccountList {
          id
          user_id
        }
        tags {
          id
          tag
        }
        logos {
          id
          original_name
          file
          mediumPath
          smallPath
          type
          extension
          size
          path
        }
        companyGoals {
          id
          name
          company_id
          description
        }
        kybChecks {
          id
        }
        companySocialLinks {
          id
          company_id
          resource
          link
        }
        companyContactInfos {
          id
          email
          tel
          company_id
          department_name
        }
      }
      suppliers {
        id
        name
        company_id
        description
        country_id
        tax_vat
        email
        tel
      }
      lastVisiblePrice {
        id
        price
        currency_id
        currency_symbol
        discount_price
        quantity_all
        discount_from_datetime
        discount_until_datetime
        availability
      }
    }
  }
`;

export const SHOW_VISIBLE_SHOP_PRODUCT = gql`
  query ($id: String!) @api(name: marketPlace) {
    showVisibleShopProduct(id: $id) {
      id
      user_id
      name
      company_id
      ingredients
      info
      is_preorder
      is_retail
      is_wholesale
      is_active
      category_id
      group_id
      deleted_at
      is_patronage
      is_negotiable_price
      negotiable_price
      status
      category {
        id
        name
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
      group {
        id
        name
        category_id
        category {
          id
          name
          add_user_id
          company_id
          description
          created_at
          updated_at
        }
        add_user_id
        company_id
        description
        created_at
        updated_at
      }
      productPrices {
        id
        userCart {
          quantity
        }
        unit {
          id
          name
          type
          system_type
          is_unit
          is_order_quantity
          is_location
          is_packing
        }
        currency {
          id
          name
          code
          symbol
          decimals
        }
        product_id
        price
        currency_id
        unit_value
        unit_id
        delivery_shipping
        delivery_digital
        delivery_pickup
        delivery_hand
        min_order_quantity
        discount_price
        has_discount_price
        quantity_all
        is_negotiable_price
        negotiable_price
        shipping_price
        shipping_price_scope
        packing_id
        packaging_footprint_price
        products_footprint_price
        weight
        weight_unit_id
        discount_from_datetime
        discount_until_datetime
        valid_from_date
        valid_until_date
        visibility
        availability
        min_order_unit_id
        shopAddress {
          id
          user_id
          user_shop_id
          name
          contact_person
          tel
          info
          address_location_id
          is_pickup
          distance
          distance_unit_id
          delivery_pickup_price
          currency_id
          currency {
            name
          }
          pivot {
            is_auto_order
          }
          schedules {
            id
            from
            to
            weekDays {
              id
              name
            }
          }
          addressLocation {
            id
            address
            lat
            lng
            country
            postcode
            locality
          }
          currency {
            id
            name
            code
            symbol
            decimals
          }
        }
        packing {
          id
          height
          width
          depth
          weight
          length_unit_id
          weight_unit_id
          max_quantity
          created_at
          updated_at
        }
        availableToBuy
      }
      tags {
        id
        tag
      }
      logos {
        id
        original_name
        file
        extension
        type
        size
        path
        mediumPath
        smallPath
      }
      company {
        id
        user_id
        type
        kyb_completed
        ext_kyb_id
        kyb_status
        is_draft
        step_number
        tax_vat
        company_name
        address_location_id
        address1
        address2
        email
        website
        established_date
        industry_group_id
        sub_industry_id
        legal_type_id
        for_profit
        is_governmental
        is_open_for_investment
        description
        created_at
        updated_at
        addressLocation {
          id
          address
          country_id
          country
        }
        industryGroup {
          id
          name
          code
        }
        subIndustry {
          id
          name
          code
          industries_id
        }
        legalType {
          id
          name
        }
        userShop {
          id
          user_id
          company_id
        }
        paymentAccountList {
          id
          user_id
        }
        tags {
          id
          tag
        }
        logos {
          id
          original_name
          file
          extension
          type
          size
          path
          mediumPath
          smallPath
        }
        companyGoals {
          id
          name
          company_id
          description
        }
        kybChecks {
          id
        }
        companySocialLinks {
          id
          company_id
          resource
          link
        }
        companyContactInfos {
          id
          email
          tel
          company_id
          department_name
        }
      }
      suppliers {
        id
        name
        company_id
        description
        country_id
        tax_vat
        email
        tel
      }
      lastVisiblePrice {
        id
        price
        currency_id
        currency_symbol
        discount_price
        quantity_all
        discount_from_datetime
        discount_until_datetime
        availability
      }
    }
  }
`;

export const DELETE_SHOP_SERVICE = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    destroyShopService(id: $id)
  }
`;

export const ADD_SHOP_SERVICE_CATEGORY = gql`
  mutation ($input: ShopServiceCategoryStoreInput!) @api(name: marketPlace) {
    addCustomShopServiceCategory(input: $input) {
      id
      name
      categoryOptions {
        id
        content
      }
      company_id
    }
  }
`;

export const SHOW_VISIBLE_SHOP_SERVICE = gql`
  query ($id: String!) @api(name: marketPlace) {
    showVisibleShopService(id: $id) {
      id
      user_id
      company_id
      customization {
        id
        service_id
        customer_id
        comment
      }
      currency {
        id
        name
        code
        symbol
        decimals
      }
      company {
        user_id
        company_name
        logos {
          id
          original_name
          file
          extension
          type
          size
          path
          mediumPath
          smallPath
        }
      }
      serviceCategory {
        id
        name
      }
      name
      description
      timezone_id
      specific_terms
      shop_service_category_id
      languages {
        id
        english_name
      }
      tags {
        id
        tag
      }
      logos {
        id
        original_name
        file
        extension
        size
        type
        pivot {
          visibility
        }
        path
        mediumPath
        smallPath
        created_at
        updated_at
      }
      performance_term_period
      performance_term_period_type
      price_type
      basic_price
      discount_price
      discount_period_start
      discount_period_end
      hourly_rate
      maximum_number_hours
      hourly_discount_price
      hourly_discount_period_start
      hourly_discount_period_end
      detailed_description
      including_vat
      is_fcem_deposit_available
      milestones {
        id
        period
        period_type
        price
        max_hours
        description
        provide_documentation
      }
      currency_id
    }
  }
`;

export const SHOW_CARBON_CREDIT = gql`
  query ($id: String!) @api(name: marketPlace) {
    showCarbonCreditById(id: $id) {
      id
      user_id
      name
      company_id
      is_draft
      is_fcem_deposit_available
      amount
      quantity
      price
      discount_price
      discount_from_datetime
      discount_until_datetime
      ext_project_id
      ext_credit_id
      link
      vintage_from
      vintage_to
      standard_id
      project_type_id
      address_location_id
      region_id
      description
      unit_id
      currency_id
      is_draft
      company {
        id
        company_name
        logos {
          id
          original_name
          file
          path
          type
          mediumPath
          smallPath
        }
      }
      provider_id
      provider {
        id
        name
      }
      standard {
        id
        name
      }
      projectType {
        id
        name
        logo
        logoPath
      }
      addressLocation {
        id
        address
      }
      region {
        id
        name
      }
      unit {
        id
        name
      }
      currency {
        id
        name
        code
        symbol
      }
      creditGoals {
        id
        name
        description
      }
      logos {
        id
        original_name
        file
        extension
        size
        type
        path
        mediumPath
        smallPath
      }
    }
  }
`;

export const CREATE_CARBON_CREDIT = gql`
  mutation ($input: CarbonCreditStoreInput!) @api(name: marketPlace) {
    createCarbonCredit(input: $input) {
      id
      user_id
      name
      company_id
      is_draft
      amount
      quantity
      price
      ext_project_id
      ext_credit_id
      link
      vintage_from
      vintage_to
      standard_id
      project_type_id
      address_location_id
      description
      currency_id
      is_draft
    }
  }
`;

export const EDIT_CARBON_CREDIT = gql`
  mutation ($input: CarbonCreditUpdateInput!) @api(name: marketPlace) {
    editCarbonCredit(input: $input) {
      id
      user_id
      name
      company_id
      is_draft
      amount
      quantity
      price
      ext_project_id
      ext_credit_id
      link
      vintage_from
      vintage_to
      standard_id
      project_type_id
      address_location_id
      description
      currency_id
      is_draft
    }
  }
`;

export const DELETE_CARBON_CREDIT = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    deleteCarbonCredit(id: $id)
  }
`;

export const DELETE_COMPANY = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    deleteCompany(id: $id)
  }
`;

export const SHOW_REGIONS = gql`
  query @api(name: marketPlace) {
    showRegions {
      id
      name
      code
    }
  }
`;

export const GET_CARBON_STANDARDS = gql`
  query @api(name: marketPlace) {
    getCarbonStandards {
      id
      name
    }
  }
`;

export const GET_CARBON_PROJECT_TYPE = gql`
  query @api(name: marketPlace) {
    getCarbonProjectType {
      id
      name
      logo
      logoPath
    }
  }
`;
export const GET_CARBON_PROVIDERS = gql`
  query @api(name: marketPlace) {
    getCarbonProviders {
      id
      name
    }
  }
`;

export const EDIT_SHOP_PRODUCT = gql`
  mutation ($input: ShopProductWithOptionsUpdateInput!) @api(name: marketPlace) {
    editShopProductWithOptions(input: $input) {
      id
    }
  }
`;

export const LOAD_SHOP_PRODUCT_FILES = gql`
  query ($shop_product_id: String!, $type: [FileType]!) @api(name: marketPlace) {
    loadAllShopProductFiles(shop_product_id: $shop_product_id, type: $type) {
      id
      original_name
      file
      extension
      size
      type
      path
    }
  }
`;

export const LOAD_SHOP_PRODUCT_LOGOS = gql`
  query ($shop_product_id: String!) @api(name: marketPlace) {
    loadAllShopProductLogos(shop_product_id: $shop_product_id) {
      id
      original_name
      file
      extension
      size
      type
      path
    }
  }
`;

export const EDIT_SHOP_PRODUCT_FILES = gql`
  mutation ($input: ShopProductFileEditDataInput!) @api(name: marketPlace) {
    editShopProductFiles(input: $input) {
      id
      original_name
      file
      extension
      size
      type
      path
    }
  }
`;

export const DELETE_PRODUCT_OPTION = gql`
  mutation ($input: ShopProductPriceDestroyInput!) @api(name: marketPlace) {
    destroyShopProductPrice(input: $input)
  }
`;

export const DELETE_PRODUCT_FILE = gql`
  mutation ($shop_product_id: String!, $id: String!) @api(name: marketPlace) {
    deleteShopProductFile(shop_product_id: $shop_product_id, id: $id)
  }
`;

export const EDIT_OR_DELETE_PRODUCT_LOGOS = gql`
  mutation ($input: ShopProductFileEditDataInput!) @api(name: marketPlace) {
    editWithDeleteProductLogos(input: $input) {
      id
      original_name
      file
      extension
      size
      type
      path
    }
  }
`;

export const INIT_ORDER_BASED_ON_USER_CART = gql`
  mutation ($input: OrderItemsInitInput!) @api(name: marketPlace) {
    initOrderBasedOnUserCart(input: $input) {
      id
    }
  }
`;

export const DELETE_ORDER_ITEMS = gql`
  mutation ($input: OrderItemsDestroyInput!) @api(name: marketPlace) {
    deleteOrderItems(input: $input)
  }
`;

export const DESTROY_ORDER_BY_ID = gql`
  mutation ($input: OrderDestroyInput!) @api(name: marketPlace) {
    destroyOrderById(input: $input)
  }
`;

export const EDIT_ORDER = gql`
  mutation ($input: [OrderUpdateInput!]!) @api(name: marketPlace) {
    editOrder(input: $input) {
      contactInfo {
        id
        first_name
        last_name
        tel
        email
        comment
      }
    }
  }
`;

export const EDIT_ORDER_DATA = gql`
  mutation ($input: [OrderUpdateInput!]!) @api(name: marketPlace) {
    editOrder(input: $input) {
      id
    }
  }
`;

export const CREATE_OR_UPDATE_ORDER_OPTIONS = gql`
  mutation ($input: OrderDeliveriesInput!) @api(name: marketPlace) {
    createOrUpdateOrderOptions(input: $input) {
      id
      delivery_method
      shop_address_id
      order_item_id
    }
  }
`;

export const CHECKOUT_ORDER = gql`
  mutation ($input: OrderCheckoutInput!) @api(name: marketPlace) {
    checkoutOrder(input: $input) {
      url
    }
  }
`;

export const GET_ORDER_BT_ID = gql`
  query ($id: String!) @api(name: marketPlace) {
    getOrderById(id: $id) {
      id
      type
      created_at
      payment_process_start
      user_id
      contact_info_id
      payment_system
      status
      payment_status
      amount
      delivery_price
      subtotal
      currency_id
      fcem_discount
      fcem_deposit
      currency {
        id
        name
        code
        symbol
        decimals
      }
      orderGroupsByDeliveries {
        id
        order_id
        company {
          user_id
          company_name
          logos {
            id
            type
            path
            original_name
            mediumPath
            smallPath
          }
        }
        company_id
        status
        payout_amount
        currency_id
        payout_status
        orderItems {
          id
          order_group_id
          delivery {
            id
            delivery_method
            shop_address_id
            order_item_id
            shopAddress {
              id
              address_location_id
              delivery_pickup_price
              currency_id
              is_pickup
              name
              schedules {
                id
                from
                to
                weekDays {
                  id
                  name
                }
              }
              addressLocation {
                id
                address
                lat
                lng
                country
                postcode
                locality
              }
              currency {
                id
                name
                code
                symbol
                decimals
              }
            }
            delivery_date
            created_at
            updated_at
            deleted_at
          }
          shop_product_id
          quantity
          price
          seller_price
          shop_product_price_id
          currency_id
          currency {
            name
          }
          availableToPay
          shopProduct {
            id
            name
            info
            logos {
              type
              path
              mediumPath
              smallPath
            }
          }
          shopProductPrice {
            id
            product_id
            price
            currency_id
            unit_value
            unit_id
            unit {
              name
            }
            min_order_quantity
            quantity_in_pack
            discount_price
            quantity_all
            productPrice
            productDiscountPrice
            shopAddress {
              id
              address_location_id
              delivery_pickup_price
              currency_id
              is_pickup
              name
              pivot {
                is_auto_order
              }
              schedules {
                id
                from
                to
                weekDays {
                  id
                  name
                }
              }
              addressLocation {
                id
                id
                address
                lat
                lng
                country
                postcode
                locality
              }
              currency {
                id
                name
                code
                symbol
                decimals
              }
            }
          }
        }
      }
      orderGroups {
        id
        order_id
        orderGroupPriceWithFee {
          groupPrice
        }
        company {
          user_id
          company_name
          logos {
            type
            path
            mediumPath
            smallPath
          }
        }
        company_id
        status
        payout_amount
        currency_id
        payout_status
        orderItems {
          id
          delivery {
            id
            delivery_method
            shop_address_id
            order_item_id
            delivery_date
            shopAddress {
              id
              address_location_id
              delivery_pickup_price
              currency_id
              is_pickup
              name
              schedules {
                id
                from
                to
                weekDays {
                  id
                  name
                }
              }
              addressLocation {
                id
                address
                lat
                lng
                country
                postcode
                locality
              }
              currency {
                id
                name
                code
                symbol
                decimals
              }
            }
            created_at
            updated_at
            deleted_at
          }
          order_group_id
          shop_product_id
          shop_service_id
          quantity
          price
          seller_price
          shop_product_price_id
          currency_id
          currency {
            name
          }
          availableToPay
          shopProduct {
            id
            name
            info
            logos {
              type
              path
              mediumPath
              smallPath
            }
          }
          shopProductPrice {
            id
            product_id
            price
            currency_id
            unit_value
            unit_id
            unit {
              name
            }
            min_order_quantity
            quantity_in_pack
            discount_price
            quantity_all
            productPrice
            productDiscountPrice
            shopAddress {
              id
              address_location_id
              delivery_pickup_price
              currency_id
              is_pickup
              name
              pivot {
                is_auto_order
              }
              schedules {
                id
                from
                to
                weekDays {
                  id
                  name
                }
              }
              addressLocation {
                id
                address
                lat
                lng
                country
                postcode
                locality
              }
              currency {
                id
                name
                code
                symbol
                decimals
              }
            }
          }
        }
      }
      contactInfo {
        id
        user_id
        first_name
        last_name
        tel
        email
        comment
      }
      products_footprint_amount
      packaging_footprint_amount
    }
  }
`;

export const GET_CUSTOMER_ORDER_BT_ID = gql`
  query ($id: String!) @api(name: marketPlace) {
    getCustomerOrderById(id: $id) {
      id
      order_id
      created_at
      order {
        id
        user {
          id
          email
          first_name
          last_name
          avatar
          tel
          roles {
            id
            name
          }
        }
        payment_process_start
        status
        type
        payment_system
        payment_status
        orderPrices {
          finalStripeFee
          finalTTFee
          finalPrice
        }
        contactInfo {
          id
          user_id
          first_name
          last_name
          tel
          email
          comment
        }
        amount
      }
      company {
        company_name
        user_id
        logos {
          path
          mediumPath
          smallPath
          type
        }
        companyContactInfos {
          id
          email
          tel
          company_id
          department_name
        }
      }
      company_id
      status
      payout_amount
      currency_id
      payout_status
      orderItems {
        id
        order_group_id
        shop_product_id
        shop_service_id
        shopService {
          id
          orderCustomization {
            id
            comment
            service {
              id
              name
              description
              company {
                company_name
              }
              performance_term_period
              performance_term_period_type
              price_type
              basic_price
              discount_price
              discount_period_start
              discount_period_end
              hourly_rate
              maximum_number_hours
              hourly_discount_price
              hourly_discount_period_start
              hourly_discount_period_end
            }
            histories {
              id
              modifier_id
              customization_id
              period_type
              period
              price
              previous_period_type
              previous_period
              previous_price
              created_at
              updated_at
              deleted_at
              user {
                id
                email
                first_name
                last_name
                avatar
                tel
                roles {
                  id
                  name
                }
              }
              milestonesHistory {
                id
                history_id
                status
                milestone_id
                files {
                  id
                  parent_id
                  original_name
                  file
                  extension
                  size
                  type
                  path
                  created_at
                  updated_at
                  smallPath
                  mediumPath
                }
                milestone {
                  id
                  period
                  period_type
                  price
                  max_hours
                  description
                  provide_documentation
                }
                period_type
                period
                price
                previous_period_type
                previous_period
                previous_price
                created_at
                updated_at
                deleted_at
              }
            }
            user {
              id
              email
              first_name
              last_name
              avatar
              tel
              roles {
                id
                name
              }
            }
          }
        }
        quantity
        price
        seller_price
        shop_product_price_id
        currency_id
        currency {
          name
        }
        delivery {
          id
          delivery_method
          shop_address_id
          order_item_id
          shopAddress {
            id
            address_location_id
            delivery_pickup_price
            currency_id
            is_pickup
            name
            schedules {
              id
              from
              to
              weekDays {
                id
                name
              }
            }
            addressLocation {
              id
              address
              lat
              lng
              country
              postcode
              locality
            }
            currency {
              id
              name
              code
              symbol
              decimals
            }
          }
          delivery_date
          created_at
          updated_at
          deleted_at
        }
        availableToPay
        shopProduct {
          id
          name
          info
          logos {
            path
            mediumPath
            smallPath
            type
          }
        }
        shopProductPrice {
          id
          availableToBuy
          product_id
          price
          currency_id
          unit_value
          unit_id
          unit {
            name
            type
          }
          min_order_quantity
          quantity_in_pack
          discount_price
          quantity_all
          productPrice
          productDiscountPrice
        }
      }
      orderGroupPriceWithFee {
        groupPrice
      }
    }
  }
`;

export const GET_SELLER_ORDER_BT_ID = gql`
  query ($input: GetSellerOrderInput!) @api(name: marketPlace) {
    getSellerOrderById(input: $input) {
      id
      order_id
      created_at
      order {
        id
        payment_process_start
        payment_system
        payment_status
        status
        user {
          id
          email
          first_name
          last_name
          avatar
          tel
          roles {
            id
            name
          }
        }
        type
        orderPrices {
          finalStripeFee
          finalTTFee
          finalPrice
        }
        contactInfo {
          id
          user_id
          first_name
          last_name
          tel
          email
          comment
        }
        amount
      }
      company {
        company_name
        logos {
          path
          mediumPath
          smallPath
          type
        }
        companyContactInfos {
          id
          email
          tel
          company_id
          department_name
        }
      }
      company_id
      status
      payout_amount
      currency_id
      payout_status
      orderItems {
        id
        delivery {
          id
          delivery_method
          shop_address_id
          order_item_id
          shopAddress {
            id
            address_location_id
            delivery_pickup_price
            currency_id
            is_pickup
            name
            schedules {
              id
              from
              to
              weekDays {
                id
                name
              }
            }
            addressLocation {
              id
              address
              lat
              lng
              country
              postcode
              locality
            }
            currency {
              id
              name
              code
              symbol
              decimals
            }
          }
          delivery_date
          created_at
          updated_at
          deleted_at
        }

        order_group_id
        shop_product_id
        shop_service_id
        shopService {
          id
          orderCustomization {
            id
            comment
            service {
              id
              name
              description
              company {
                company_name
              }
              performance_term_period
              performance_term_period_type
              price_type
              basic_price
              discount_price
              discount_period_start
              discount_period_end
              hourly_rate
              maximum_number_hours
              hourly_discount_price
              hourly_discount_period_start
              hourly_discount_period_end
            }
            histories {
              id
              modifier_id
              customization_id
              period_type
              period
              price
              previous_period_type
              previous_period
              previous_price
              created_at
              updated_at
              deleted_at
              user {
                id
                email
                first_name
                last_name
                avatar
                tel
                roles {
                  id
                  name
                }
              }
              milestonesHistory {
                id
                history_id
                milestone_id
                status
                comment
                files {
                  id
                  parent_id
                  original_name
                  file
                  extension
                  size
                  type
                  path
                  created_at
                  updated_at
                  smallPath
                  mediumPath
                }
                milestone {
                  id
                  period
                  period_type
                  price
                  max_hours
                  description
                  provide_documentation
                }
                period_type
                period
                price
                previous_period_type
                previous_period
                previous_price
                created_at
                updated_at
                deleted_at
              }
            }
            user {
              id
              email
              first_name
              last_name
              avatar
              tel
              roles {
                id
                name
              }
            }
          }
        }
        quantity
        price
        seller_price
        shop_product_price_id
        currency_id
        currency {
          name
        }
        availableToPay
        shopProduct {
          name
          info
          logos {
            path
            mediumPath
            smallPath
            type
          }
        }
        shopProductPrice {
          id
          product_id
          price
          currency_id
          unit_value
          unit_id
          unit {
            name
            type
          }
          min_order_quantity
          quantity_in_pack
          discount_price
          quantity_all
          productPrice
          productDiscountPrice
        }
      }
      orderGroupPriceWithFee {
        groupPrice
      }
    }
  }
`;

export const SHOW_CURRENT_USER_CONTACT_INFOS = gql`
  query @api(name: marketPlace) {
    showCurrentUserContactInfos {
      id
      first_name
      last_name
      tel
      email
      comment
    }
  }
`;

export const CREATE_CONTACT_INFO = gql`
  mutation ($input: ContactInfoStoreInput!) @api(name: marketPlace) {
    createContactInfo(input: $input) {
      id
      first_name
      last_name
      tel
      email
      comment
    }
  }
`;

export const CREATE_SERVICE_ORDER = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    createServiceOrder(id: $id) {
      id
      status
      type
    }
  }
`;

export const UPDATE_CONTACT_INFO = gql`
  mutation ($input: ContactInfoUpdateInput!) @api(name: marketPlace) {
    updateContactInfo(input: $input) {
      id
      first_name
      last_name
      tel
      email
      comment
    }
  }
`;

export const GET_CANCEL_ORDER_REASONS = gql`
  query ($input: OrderViewerInput!) @api(name: marketPlace) {
    getCancelOrderReasons(input: $input) {
      id
      text
    }
  }
`;

export const CHANGE_ORDER_STATUS_SHIPPED = gql`
  mutation ($input: ChangeOrderStatusInput!) @api(name: marketPlace) {
    changeOrderStatus(input: $input) {
      id
      order_id
      created_at
      order {
        id
        amount
        status
        payment_process_start
        payment_system
        payment_status
        contactInfo {
          id
          user_id
          first_name
          last_name
          tel
          email
          comment
        }
      }
      company {
        company_name
        logos {
          path
          mediumPath
          smallPath
          type
        }
      }
      company_id
      status
      payout_amount
      currency_id
      payout_status
      orderItems {
        id
        delivery {
          id
          delivery_method
          shop_address_id
          order_item_id
          shopAddress {
            id
            address_location_id
            delivery_pickup_price
            currency_id
            is_pickup
            name
            schedules {
              id
              from
              to
              weekDays {
                id
                name
              }
            }
            addressLocation {
              id
              address
              lat
              lng
              country
              postcode
              locality
            }
            currency {
              id
              name
              code
              symbol
              decimals
            }
          }
          delivery_date
          created_at
          updated_at
          deleted_at
        }

        order_group_id
        shop_product_id
        quantity
        price
        seller_price
        shop_product_price_id
        currency_id
        currency {
          name
        }
        availableToPay
        shopProduct {
          name
          info
          logos {
            path
            mediumPath
            smallPath
            type
          }
        }
        shopProductPrice {
          id
          product_id
          price
          currency_id
          unit_value
          unit_id
          unit {
            name
            type
          }
          min_order_quantity
          quantity_in_pack
          discount_price
          quantity_all
          productPrice
          productDiscountPrice
        }
      }
      orderGroupPriceWithFee {
        groupPrice
      }
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation ($input: OrderCancelInput!) @api(name: marketPlace) {
    cancelOrder(input: $input) {
      id
      status
      cancel_reason_id
    }
  }
`;

export const CREATE_CERT_ORDER_WITH_DEPOSIT = gql`
  mutation ($input: CarbonCreditInput!) @api(name: marketPlace) {
    reservationCarbonCredit(input: $input) {
      id
      credit_id
      order_id
      user_id
      amount
      unit
      status
    }
  }
`;
export const DELETE_COMPANY_PICKUP_ADDRESS = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    deletePickupAddress(id: $id)
  }
`;

export const CREATE_OR_UPDATE_COMPANY_PICKUP_ADDRESSES = gql`
  mutation ($input: ShopAddressInput!) @api(name: marketPlace) {
    createOrUpdateCompanyPickupAddress(input: $input) {
      id
      user_id
      user_shop_id
      name
      contact_person
      tel
      email
      info
      address_location_id
      addressLocation {
        id
        address
      }
      availability
      district
      deadline_days
      is_pickup
      distance
      distance_unit_id
      delivery_pickup_price
      currency_id
      currency {
        name
      }
      created_at
      schedules {
        id
        from
        to
        weekDays {
          id
          name
        }
      }
    }
  }
`;

export const GET_SHOP_SERVICE_CATEGORIES = gql`
  query ($company_id: String!) @api(name: marketPlace) {
    getShopServiceCategories(company_id: $company_id) {
      id
      name
      categoryOptions {
        id
        content
      }
      company_id
    }
  }
`;

export const GET_ALL_LANGUAGES = gql`
  query @api(name: marketPlace) {
    getAllLanguages {
      id
      code
      iso639_1
      english_name
      native_name
    }
  }
`;

export const GET_TIMEZONES = gql`
  query @api(name: marketPlace) {
    getTimezones {
      id
      name
    }
  }
`;

export const CREATE_SHOP_SERVICE = gql`
  mutation ($input: ShopServiceStoreInput!) @api(name: marketPlace) {
    createShopService(input: $input) {
      id
      name
      company_id
      company {
        user_id
        company_name
        logos {
          id
          type
          path
          original_name
          mediumPath
          smallPath
        }
      }
    }
  }
`;

export const EDIT_SHOP_SERVICE = gql`
  mutation ($input: ShopServiceUpdateInput!) @api(name: marketPlace) {
    editShopService(input: $input) {
      id
      company_id
      company {
        user_id
        company_name
        logos {
          id
          type
          path
          original_name
          mediumPath
          smallPath
        }
      }
      name
      description
      timezone_id
      specific_terms
      shop_service_category_id
      languages {
        id
        english_name
      }
      serviceCategory {
        id
        name
      }
      tags {
        id
        tag
      }
      logos {
        id
        original_name
        file
        extension
        size
        type
        pivot {
          visibility
        }
        path
        mediumPath
        smallPath
        created_at
        updated_at
      }
      performance_term_period
      performance_term_period_type
      price_type
      basic_price
      discount_price
      discount_period_start
      discount_period_end
      hourly_rate
      maximum_number_hours
      hourly_discount_price
      hourly_discount_period_start
      hourly_discount_period_end
      detailed_description
      including_vat
      is_fcem_deposit_available
      milestones {
        id
        period
        period_type
        price
        max_hours
        description
        provide_documentation
      }
      currency_id
    }
  }
`;

export const SHOW_SHOP_SERVICE = gql`
  query ($id: String!) @api(name: marketPlace) {
    showShopService(id: $id) {
      id
      user_id
      company_id
      customization {
        id
        service_id
        customer_id
        comment
      }
      currency {
        id
        name
        code
        symbol
        decimals
      }
      company {
        user_id
        company_name
        logos {
          id
          type
          path
          original_name
          mediumPath
          smallPath
        }
      }
      serviceCategory {
        id
        name
      }
      name
      description
      timezone_id
      specific_terms
      shop_service_category_id
      languages {
        id
        english_name
      }
      tags {
        id
        tag
      }
      logos {
        id
        original_name
        parent_id
        file
        extension
        size
        type
        pivot {
          visibility
        }
        path
        created_at
        updated_at
        smallPath
        mediumPath
      }
      performance_term_period
      performance_term_period_type
      price_type
      basic_price
      discount_price
      discount_period_start
      discount_period_end
      hourly_rate
      maximum_number_hours
      hourly_discount_price
      hourly_discount_period_start
      hourly_discount_period_end
      detailed_description
      including_vat
      is_fcem_deposit_available
      milestones {
        id
        period
        period_type
        price
        max_hours
        description
        provide_documentation
      }
      currency_id
    }
  }
`;

export const LOAD_SHOP_SERVICE_LOGOS = gql`
  query ($shop_service_id: String!) @api(name: marketPlace) {
    loadAllShopServiceLogos(shop_service_id: $shop_service_id) {
      id
      original_name
      file
      extension
      size
      type
      parent_id
      path
      smallPath
      mediumPath
    }
  }
`;

export const EDIT_OR_DELETE_SERVICE_LOGOS = gql`
  mutation ($input: ShopServiceFileEditDataInput!) @api(name: marketPlace) {
    editWithDeleteServiceLogos(input: $input) {
      id
      original_name
      file
      extension
      size
      type
      path
    }
  }
`;

export const CREATE_CUSTOMIZATION_REQUEST = gql`
  mutation ($input: CustomizationStoreInput!) @api(name: marketPlace) {
    createCustomization(input: $input) {
      id
      service_id
      customer_id
      comment
      status
    }
  }
`;

export const REQUEST_CHANGES = gql`
  mutation ($input: RequestChangesInput!) @api(name: marketPlace) {
    requestChanges(input: $input) {
      id
      history_id
      milestone_id
      status
      comment
    }
  }
`;

export const ADD_CUSTOMIZATION_HISTORY = gql`
  mutation ($input: CustomizationStoreHistoryInput!) @api(name: marketPlace) {
    addCustomizationHistory(input: $input) {
      id
      modifier_id
      customization {
        id
        created_at
        updated_at
      }
      customization_id
      user {
        id
        email
        first_name
        last_name
        avatar
        tel
        roles {
          id
          name
        }
      }
      period_type
      period
      price
      previous_period_type
      previous_period
      previous_price
      created_at
      updated_at
      deleted_at
      milestonesHistory {
        id
        history_id
        milestone_id
        period_type
        period
        price
        previous_period_type
        previous_period
        previous_price
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

export const CANCEL_CUSTOMIZATION = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    cancelCustomization(id: $id)
  }
`;

export const ACCEPT_CUSTOMIZATION = gql`
  mutation ($id: String!) @api(name: marketPlace) {
    acceptCustomization(id: $id)
  }
`;

export const GET_SELLER_ALL_CUSTOMIZATIONS = gql`
  query ($input: FilterCustomizationsInput) @api(name: marketPlace) {
    getSellerAllCustomizations(input: $input) {
      id
      service_id
      status
      customer_id
      comment
      created_at
      updated_at
      service {
        id
        name
        performance_term_period
        performance_term_period_type
        price_type
        basic_price
        discount_price
        discount_period_start
        discount_period_end
        hourly_rate
        maximum_number_hours
        hourly_discount_price
        hourly_discount_period_start
        hourly_discount_period_end
      }
      user {
        id
        email
        first_name
        last_name
        avatar
        tel
      }
      histories {
        id
        modifier_id
        customization_id
        period_type
        period
        price
        previous_period_type
        previous_period
        previous_price
        created_at
        updated_at
        deleted_at
        milestonesHistory {
          id
          history_id
          milestone_id
          milestone {
            id
            period
            period_type
            price
            max_hours
            description
            provide_documentation
          }
          period_type
          period
          price
          previous_period_type
          previous_period
          previous_price
          created_at
          updated_at
          deleted_at
        }
      }
    }
  }
`;

export const GET_CUSTOMER_ALL_CUSTOMIZATIONS = gql`
  query ($input: FilterCustomizationsInput) @api(name: marketPlace) {
    getCustomerAllCustomizations(input: $input) {
      id
      service_id
      status
      customer_id
      comment
      created_at
      updated_at
      service {
        id
        name
        performance_term_period
        performance_term_period_type
        price_type
        basic_price
        discount_price
        discount_period_start
        discount_period_end
        hourly_rate
        maximum_number_hours
        hourly_discount_price
        hourly_discount_period_start
        hourly_discount_period_end
      }
      user {
        id
        email
        first_name
        last_name
        avatar
        tel
      }
      histories {
        id
        modifier_id
        customization_id
        period_type
        period
        price
        previous_period_type
        previous_period
        previous_price
        created_at
        updated_at
        deleted_at
        milestonesHistory {
          id
          history_id
          milestone_id
          milestone {
            id
            period
            period_type
            price
            max_hours
            description
            provide_documentation
          }
          period_type
          period
          price
          previous_period_type
          previous_period
          previous_price
          created_at
          updated_at
          deleted_at
        }
      }
    }
  }
`;

export const GET_CUSTOMIZATION_BY_ID = gql`
  query ($id: String!) @api(name: marketPlace) {
    getCustomizationById(id: $id) {
      id
      service_id
      status
      customer_id
      comment
      created_at
      updated_at
      service {
        id
        company {
          company_name
        }
        user_id
        name
        description
        performance_term_period
        performance_term_period_type
        price_type
        basic_price
        discount_price
        discount_period_start
        discount_period_end
        hourly_rate
        maximum_number_hours
        hourly_discount_price
        hourly_discount_period_start
        hourly_discount_period_end
      }
      user {
        id
        email
        first_name
        last_name
        avatar
        tel
        roles {
          id
          name
        }
      }
      histories {
        id
        modifier_id
        user {
          id
          email
          first_name
          last_name
          avatar
          tel
          roles {
            id
            name
          }
        }
        customization_id
        period_type
        period
        price
        previous_period_type
        previous_period
        previous_price
        created_at
        updated_at
        deleted_at
        milestonesHistory {
          id
          history_id
          milestone_id
          milestone {
            id
            period
            period_type
            price
            max_hours
            description
            provide_documentation
          }
          period_type
          period
          price
          previous_period_type
          previous_period
          previous_price
          created_at
          updated_at
          deleted_at
        }
      }
    }
  }
`;

export const CHECK_CERT_RESERVATION_STATUS = gql`
  query ($order_id: String!) @api(name: marketPlace) {
    checkReservationStatus(order_id: $order_id) {
      status
    }
  }
`;

export const requestBlockchainProxy = async (payload) =>
  axiosClient.post(
    `${process.env.REACT_APP_CHAIN_RPC_API_URL}/api`,
    payload,
    axiosBlockchainProxyConfigPrivate()
  );
