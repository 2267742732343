import React, { useEffect, useState } from 'react';
import { Button, InfoIcon, Modal, Typography } from 'tt-ui-kit';
import { TimePicker } from 'tt-ui-lib/core';
import InputAdornment from '@mui/material/InputAdornment';
import styles from './addNewPickupModal.module.scss';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField/TextField';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import Image from '../../../../../Image';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LocationModal from '../../../../../locationModal';
import { useMutation } from '@apollo/client';
import { CREATE_ADDRESS_LOCATION, EDIT_ADDRESS_LOCATION } from '../../../../../../api';
import SelectComponent from 'components/select';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ReactPhoneInput from 'react-phone-input-2';
import clsx from 'clsx';
import dayjs from 'dayjs';
import NumberMaskInput from '../../../../../NumberMaskInput';

const weekDays = [
  { name: 'Mon', id: 1 },
  { name: 'Tue', id: 2 },
  { name: 'Wed', id: 3 },
  { name: 'Thu', id: 4 },
  { name: 'Fri', id: 5 },
  { name: 'Sat', id: 6 },
  { name: 'Sun', id: 7 },
];
const CustomizedAccordion = {
  '& .MuiButtonBase-root': {
    '&.Mui-expanded': {
      transform: 'none',
    },
    padding: '0px !important',
    margin: '0px !important',
    minHeight: '0px !important',
    '& .MuiAccordionSummary-content': {
      margin: 0,
      paddingRight: '25px',
      '& .MuiTypography-root': {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#212121',
      },
    },
  },
  '&.MuiPaper-root::before': {
    backgroundColor: 'transparent',
  },
};

const tooltipProps = {
  arrow: {
    sx: {
      color: '#999999',
    },
  },
  tooltip: {
    sx: {
      padding: '12px 20px',
      border: 'none',
      borderRadius: '12px',
      backgroundColor: '#00000066',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
    },
  },
};

const visibilityList = [
  {
    id: 1,
    name: 'Anyone',
    value: 'anyone',
  },
  {
    id: 2,
    name: 'Only company connections',
    value: 'connections',
  },
  {
    id: 3,
    name: 'Only company followers',
    value: 'followers',
  },
  {
    id: 4,
    name: 'No one',
    value: 'no_one',
  },
];

const helperTextStyles = {
  marginTop: '2px',
  marginLeft: 0,
  marginRight: 0,
};

export const AddNewPickupModal = ({
  open,
  onClose,
  methods,
  pickupAppend,
  pickupEdit,
  currenciesList,
  editData,
  selectedCurrency,
  setSelectedCurrency,
  isEditModal,
  setIsEditModal,
}) => {
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [selectedVisibility, setSelectedVisibility] = useState({});
  const [expandedIds, setExpandedIds] = useState([]);
  const [getLocationIdData, setGetLocationIdData] = useState(null);
  const [createAddressLocation] = useMutation(CREATE_ADDRESS_LOCATION);
  const [editAddressLocation] = useMutation(EDIT_ADDRESS_LOCATION);
  const [phoneInputLabel, setPhoneInputLabel] = useState('');

  const {
    setValue,
    reset,
    control,
    getValues,
    register,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const {
    fields: scheduleFields,
    append: scheduleAppend,
    update: scheduleUpdate,
    remove: scheduleRemove,
  } = useFieldArray({
    control,
    name: 'schedules',
    keyName: 'key',
  });

  useEffect(() => {
    setValue('currency_id', selectedCurrency);
  }, [open, selectedCurrency]);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const validate = () => {
    if (errors && errors.schedules) {
      const expandedIdsWithErrors = [];
      errors.schedules.map((item, index) => {
        if (item) {
          expandedIdsWithErrors.push(index);
        }
      });
      let uniqueItems = Array.from(new Set([...expandedIdsWithErrors, ...expandedIds]));
      setExpandedIds(uniqueItems);
    }
  };

  const createOrUpdateAddressLocation = async (addressLocationInput, currentLocationId) => {
    if (isEditModal) {
      let editAddressLocationData = await editAddressLocation({
        variables: {
          input: { ...addressLocationInput, id: currentLocationId },
        },
      });
      return editAddressLocationData.data.editAddressLocation;
    } else {
      let createAddressLocationData = await createAddressLocation({
        variables: {
          input: addressLocationInput,
        },
      });
      return createAddressLocationData.data.createAddressLocation;
    }
  };

  const onSubmit = async (data) => {
    const locationId = await createOrUpdateAddressLocation(
      getLocationIdData,
      data.address_location_id
    );
    if (locationId) {
      data.address_location_id = locationId.id;
      if (isEditModal) {
        pickupEdit(data);
      } else {
        pickupAppend(data);
      }
      cancelModal();
    }
  };

  const addOption = () => {
    const data = {
      weekDays: [],
      from: null,
      to: null,
      schedules: [],
      orderStorageTime: '',
      excludeSomeDates: false,
      dates: [],
    };
    setExpandedIds((currentExpandedIds) => [...currentExpandedIds, scheduleFields.length]);
    scheduleAppend(data);
  };

  const deleteSchedule = (e, index) => {
    e.stopPropagation();
    scheduleRemove(index);
  };

  const addDay = (data, index) => {
    const schedules = getValues('schedules');
    let weekDays = [...schedules[index].weekDays];
    if (schedules[index].weekDays.find((weekDay) => weekDay.id === data.id)) {
      weekDays = weekDays.filter((day) => day.id !== data.id);
    } else {
      weekDays.push(data);
    }
    scheduleUpdate(index, {
      ...schedules[index],
      weekDays,
    });
    setValue(`schedules[${index}].weekDays[]`, weekDays);

    if (getValues(`schedules[${index}].weekDays[]`).length === 0) {
      setError(`schedules[${index}].weekDays[]`, {
        type: 'custom',
        message: 'Please fill out the rest fields. This is mandatory.',
      });
    } else {
      clearErrors(`schedules[${index}].weekDays[]`);
    }
  };

  useEffect(() => {
    if (scheduleFields && !scheduleFields.length) {
      addOption();
    }
  }, []);

  useEffect(() => {
    if (isEditModal) {
      for (const dataKey in editData) {
        if (dataKey === 'addressLocation') {
          const updatedData = { ...editData[dataKey] };
          delete updatedData.__typename;
          setGetLocationIdData(updatedData);
        }
        if (dataKey === 'schedules') {
          const updatedSchedules = editData[dataKey].map((schedule) => {
            const formattedFrom = new Date(schedule.from);
            const formattedTo = new Date(schedule.to);

            return {
              ...schedule,
              from: formattedFrom,
              to: formattedTo,
            };
          });
          setValue(dataKey, updatedSchedules);
        } else {
          setValue(dataKey, editData[dataKey]);
        }
      }
    }
  }, [isEditModal]);

  const openCloseExpanded = (index) => {
    if (scheduleFields.length > 0) {
      if (expandedIds.includes(index)) {
        setExpandedIds((currentExpandedIds) => currentExpandedIds.filter((item) => item !== index));
      } else {
        setExpandedIds((currentExpandedIds) => [...currentExpandedIds, index]);
      }
    }
  };

  const handleApplyLocation = async (locationValue) => {
    setGetLocationIdData({
      address: locationValue.address,
      data: {
        latLng: {
          lat: locationValue.coordinates.lat.toString(),
          lng: locationValue.coordinates.lng.toString(),
        },
        country: locationValue.locationData.country,
        country_code: locationValue.locationData.country_code,
        locality: locationValue.locationData.locality,
        postcode: locationValue.locationData.postcode,
      },
    });
    setValue('addressLocation.address', locationValue.address);
  };

  const cancelModal = () => {
    reset({
      schedules: [
        {
          weekDays: [],
          from: null,
          to: null,
          schedules: [],
          orderStorageTime: '',
          excludeSomeDates: false,
          dates: [],
        },
      ],
    });
    onClose();
    if (isEditModal) {
      setIsEditModal(!isEditModal);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => cancelModal()}
      onClick={(e) => e.stopPropagation()}
      title={isEditModal ? 'Edit pickup' : 'New pickup'}
    >
      <form style={{ width: '100%' }}>
        <Box className={styles.formContent}>
          <Box className={styles.formGroup}>
            <Box className={styles.formControl}>
              <Controller
                name="addressLocation.address"
                defaultValue=""
                rules={{
                  required: {
                    message: 'Please fill out the rest fields. This is mandatory.',
                    value: true,
                  },
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    className={styles.locationInputSeller}
                    style={{ width: '100% !important' }}
                    label="Location*"
                    data-type="location"
                    value={value}
                    onClick={() => setIsLocationModal('handAddresses')}
                    onChange={onChange}
                    error={
                      !getValues('addressLocation.address') && !!errors.addressLocation?.address
                    }
                    helperText={
                      !getValues('addressLocation.address') &&
                      errors?.addressLocation?.address?.message
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    FormHelperTextProps={{
                      style: { ...helperTextStyles },
                    }}
                  />
                )}
              />
            </Box>
            <Box className={`${styles.formControl} ${styles.formControlColumn}`}>
              <Controller
                name="name"
                defaultValue=""
                rules={{
                  required: {
                    message: 'Please fill out the rest fields. This is mandatory.',
                    value: true,
                  },
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    className={styles.locationInputSeller}
                    style={{ width: '100% !important' }}
                    label="Pickup name *"
                    data-type="name"
                    value={value}
                    onChange={onChange}
                    error={!getValues('name') && !!errors.name}
                    helperText={!getValues('name') && errors?.name?.message}
                    FormHelperTextProps={{
                      style: { ...helperTextStyles },
                    }}
                  />
                )}
              />
              <Controller
                name="district"
                defaultValue=""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    className={styles.locationInputSeller}
                    style={{ width: '100% !important' }}
                    label="District"
                    data-type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Box>
            <Box className={`${styles.formControl} ${styles.formControlColumn}`}>
              <Controller
                name={'deadline_days'}
                defaultValue=""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      className={styles.locationInputSeller}
                      style={{ width: '100% !important' }}
                      label="Order deadline (day(s) before pickup)"
                      data-type="number"
                      type="number"
                      onKeyDown={(e) =>
                        ['e', 'E', '+', '-', 'v', 'V'].includes(e.key) && e.preventDefault()
                      }
                      onChange={onChange}
                      value={value}
                    />
                  </>
                )}
              />
              <Box className={styles.shippingGroup}>
                <NumberMaskInput
                  {...register('delivery_pickup_price', {
                    value: '',
                    pattern: {
                      value: /^(?:[1-9]\d{0,5}(?:\.\d{1,2})?|0\.\d{1,2}|999999\.99)$/,
                      message: 'Please enter a number between 0.1 and 999999.99.',
                    },
                  })}
                  className={styles.locationInputSeller}
                  label="Shipping costs"
                  value={watch('delivery_pickup_price')}
                  data-type="available-schedules"
                  InputProps={{
                    endAdornment: <Typography className={styles.priceValue}>USD</Typography>,
                  }}
                  onChange={(e) => {
                    setValue('delivery_pickup_price', e.target.value);
                  }}
                  error={errors?.delivery_pickup_price}
                  helperText={errors?.delivery_pickup_price?.message}
                />
                <Tooltip
                  slotProps={tooltipProps}
                  title="You don't have to specify the cost if it's free"
                  placement="top"
                  arrow={true}
                  enterTouchDelay={0}
                >
                  <IconButton className={styles.infoIconBtn}>
                    <InfoIcon className={styles.infoIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box className={styles.formControl}>
              <SelectComponent
                key={visibilityList.length}
                values={visibilityList}
                valueField="id"
                labelField="name"
                required={false}
                name="availability"
                placeholder="Visibility"
                register={register}
                getValues={getValues}
                changeValue={(value) => setSelectedVisibility(value)}
              />
            </Box>
            <Box className={styles.formControl}>
              <Controller
                name={'info'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      className={styles.locationInputSeller}
                      style={{ width: '100% !important' }}
                      label="Comment"
                      data-type="text"
                      value={value}
                      onChange={onChange}
                      multiline
                      rows={2}
                    />
                  </>
                )}
              />
            </Box>
          </Box>
          <Box className={styles.formGroup} style={{ gap: '8px' }}>
            <Typography className={styles.fieldTitle}>Contact information</Typography>
            <Box className={`${styles.formControl} ${styles.formControlColumn}`}>
              <Controller
                name={'contact_person'}
                defaultValue=""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      className={styles.locationInputSeller}
                      style={{ width: '100% !important' }}
                      label="Contact Person"
                      data-type="text"
                      value={value}
                      onChange={onChange}
                    />
                  </>
                )}
              />
              <TextField
                {...register('email', {
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address.',
                  },
                })}
                className={styles.locationInputSeller}
                style={{ width: '100% !important' }}
                label="Email"
                data-type="email"
                error={!!errors?.email}
                helperText={errors?.email?.message}
                FormHelperTextProps={{
                  style: { ...helperTextStyles },
                }}
              />
              <Controller
                name="tel"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Box sx={{ width: '100%' }}>
                    <ReactPhoneInput
                      onChange={onChange}
                      onFocus={() => setPhoneInputLabel('Phone')}
                      onBlur={() => setPhoneInputLabel('')}
                      value={value}
                      specialLabel={value ? 'Phone' : phoneInputLabel}
                      placeholder="Phone"
                      buttonClass={styles.phone}
                      inputClass={clsx(
                        styles.phoneInput,
                        errors.tel?.message ? styles.errorInput : ''
                      )}
                    />
                    {errors.tel?.message && (
                      <div className={styles.errorMessage}>{errors.tel?.message}</div>
                    )}
                  </Box>
                )}
              />
            </Box>
          </Box>
          <Box className={styles.formGroup}>
            {scheduleFields.map((schedule, index) => (
              <Accordion
                sx={CustomizedAccordion}
                key={schedule.key}
                className={styles.accordionContainer}
                expanded={expandedIds.includes(index)}
                onChange={() => openCloseExpanded(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="store-schedule"
                  id="schedule"
                >
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Typography>Store schedule*</Typography>
                    {scheduleFields.length > 1 && (
                      <Button
                        type="link"
                        className={styles.deleteSchedule}
                        onClick={(e) => deleteSchedule(e, index)}
                      >
                        <Typography className={styles.deleteScheduleBtnText}>
                          delete schedule
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px !important' }}>
                  <Box className={styles.scheduleContent}>
                    <input
                      type="hidden"
                      {...register(`schedules[${index}].weekDays[]`, {
                        required: 'Please fill out the rest fields. This is mandatory.',
                        value: [],
                      })}
                    />
                    <Box className={styles.scheduleGroup}>
                      {weekDays.map((day, i) => (
                        <Button
                          className={`${styles.weekDayBtn}
                          ${
                            schedule.weekDays.length > 0 &&
                            schedule.weekDays.find((weekDay) => weekDay.id === day.id) &&
                            styles.weekDayBtnActive
                          }`}
                          key={i}
                          onClick={(e) => {
                            e.stopPropagation();
                            addDay(day, index);
                          }}
                        >
                          {day.name}
                        </Button>
                      ))}
                    </Box>
                    {errors.schedules &&
                      errors.schedules[index] &&
                      errors.schedules[index].weekDays && (
                        <Typography className={styles.errorText}>
                          {errors.schedules[index].weekDays.message}
                        </Typography>
                      )}
                    <Box className={`${styles.formControl} ${styles.formControlColumn}`}>
                      <Box className={styles.timePickerWithError}>
                        <Controller
                          name={`schedules[${index}].from`}
                          defaultValue=""
                          rules={{
                            required: {
                              message: 'Please fill out the rest fields. This is mandatory.',
                              value: true,
                            },
                          }}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TimePicker
                              label="From"
                              onChange={onChange}
                              value={value ? dayjs(value) : value}
                              status={
                                errors.schedules &&
                                errors.schedules[index] &&
                                !!errors.schedules[index].from
                                  ? 'error'
                                  : undefined
                              }
                            />
                          )}
                        />
                        {errors.schedules &&
                          errors.schedules[index] &&
                          errors.schedules[index].from && (
                            <Typography className={styles.errorText}>
                              {errors.schedules[index].from.message}
                            </Typography>
                          )}
                      </Box>
                      <Box className={styles.timePickerWithError}>
                        <Controller
                          name={`schedules[${index}].to`}
                          control={control}
                          rules={{
                            required: {
                              message: 'Please fill out the rest fields. This is mandatory.',
                              value: true,
                            },
                          }}
                          render={({ field: { onChange, value, error } }) => (
                            <TimePicker
                              label="To"
                              onChange={onChange}
                              value={value ? dayjs(value) : value}
                              status={
                                errors.schedules &&
                                errors.schedules[index] &&
                                !!errors.schedules[index].from
                                  ? 'error'
                                  : undefined
                              }
                            />
                          )}
                        />
                        {errors.schedules &&
                          errors.schedules[index] &&
                          errors.schedules[index].to && (
                            <Typography className={styles.errorText}>
                              {errors.schedules[index].to.message}
                            </Typography>
                          )}
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
            <Button type="link" className={styles.addSchedule} onClick={addOption}>
              <Image src="/images/company/plus.svg" />
              <Typography className={styles.btnText}>Add a new schedule</Typography>
            </Button>
          </Box>
          <Box className={styles.modalFooter}>
            <Box className={styles.buttonGroup}>
              <Button type="default" className={styles.formBtn} onClick={cancelModal}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  methods
                    .handleSubmit(onSubmit)()
                    .finally(() => {
                      validate();
                    });
                }}
                className={styles.formBtn}
              >
                {isEditModal ? 'Save' : 'Create'}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
      <LocationModal
        title="Choose your location"
        isModelOpen={isLocationModal}
        handleClose={() => setIsLocationModal(false)}
        handleApply={handleApplyLocation}
      />
    </Modal>
  );
};
